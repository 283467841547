//jsxhook

import {
  Checkbox,
  Column,
  EmailInput,
  ErrorCard as _ErrorCard,
  FormError,
  H5,
  H6,
  LinkVariants,
  PrimaryButton,
  Select,
  SuccessCard,
  TextArea,
  TextInput
} from "@sixty-six-north/ui-system"
import {
  Field,
  Form,
  Formik,
  FormikHelpers,
  useField,
  useFormikContext,
  FormikProps
} from "formik"
import React, { useState } from "react"
import { Trans as SimpleTrans, useTranslation } from "react-i18next"
import { Box, Flex, Grid } from "theme-ui"
import * as Yup from "yup"
import {
  AddressLineOne,
  AddressLineTwo,
  Country
} from "../../checkout/components/AddressForm"
import { useStoreContext } from "../../i18n/StoreHooks"
import { useKlaviyo } from "../../klaviyo"
import { RecaptchaNotice } from "../../recaptcha/RecaptchaNotice"
import { useGoogleAnalytics } from "../../tagmanager/AnalyticsHooks"
import { extractUTMData } from "../../tagmanager/NewsletterSignUpTrackingData"
import { useContactUs, useSponsorship, useSubscribe } from "../forms/FormHooks"
import { SponsorshipFormData } from "../forms/SponsorshipFormData"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { PrismicDocumentLink } from "./PrismicLink"
import { getBaseCol, processTextAlignment } from "./Text"

const STORE_SIGNUP_TAG = "store_signup"
const SIGNUP_STORES = [
  "kringlan",
  "midhraun",
  "smaralind",
  "bankastraeti_pos",
  "laugavegur",
  "leiftstod_airport",
  "faxafen",
  "faxafen_outlet",
  "hafnarstraeti",
  "hafnartorg_pos",
  "skipagata_outlet",
  "ostergade",
  "svaertegade",
  "keflavik_airport",
  "vestmannaeyjar"
]

export const ContactFormSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const BaseCol = getBaseCol(data.primary.width)
  const textLayout = processTextAlignment(
    BaseCol,
    data.primary.width,
    data.primary.text_alignment
  )

  const { t } = useTranslation("forms")
  const contactUs = useContactUs()
  const store = useStoreContext()

  const selectedCountry = store.isShoppingInEurope()
    ? ""
    : store.country.toUpperCase()
  const [isLoading, setLoading] = useState(false)

  function onSubmit(values: ContactUsFormData, helpers) {
    setLoading(true)

    return contactUs
      .contactUs(values)
      .catch(err => {
        helpers.setStatus({ status: "error" })
        return Promise.reject(err)
      })
      .then(isSuccessful => {
        if (isSuccessful) {
          helpers.resetForm()
          helpers.setStatus({ status: "success" })
        } else {
          helpers.setStatus({ status: "error" })
        }
        return isSuccessful
      })
      .finally(() => {
        helpers.setSubmitting(false)
        setLoading(false)
      })
  }

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Column sx={{ ...textLayout }}>
        <Formik
          initialValues={{
            name: "",
            emailAddress: "",
            phoneNumber: "",
            message: "",
            country: selectedCountry,
            inquiryReason: ""
          }}
          onSubmit={onSubmit}
          validationSchema={ContactUsFormSchema(t)}
        >
          {props => (
            <Form>
              {formStatus(props, "error") && (
                <Box sx={{ marginBottom: 24 }}>
                  <_ErrorCard
                    title={t("formSubmitError")}
                    message={t("formSubmitErrorMessage")}
                  />
                </Box>
              )}
              {formStatus(props, "success") && (
                <Box sx={{ marginBottom: 24 }}>
                  <SuccessCard
                    title={t("contactUsSuccess")}
                    message={t("contactUsSuccessMessage")}
                  />
                </Box>
              )}
              <Box
                sx={{
                  "& > div + *": { mt: 8 }
                }}
              >
                <Grid columns={2} gap={8}>
                  <YourName />
                  <EmailAddress />
                </Grid>
                <PhoneNumber />
                <ReasonForInquiry />
                <Country />
                <Message />
                <PrimaryButton
                  type="submit"
                  fill={true}
                  loading={isLoading}
                  id="submitFormButton"
                >
                  {t("submit", "Submit")}
                </PrimaryButton>
              </Box>
            </Form>
          )}
        </Formik>

        <RecaptchaNotice />
      </Column>
    </PrismicElementWrapper>
  )
}

export function formStatus(
  props: FormikProps<unknown>,
  status: "error" | "success"
) {
  return props.status && props.status.status === status
}

export const MailchimpSubscriptionFormSlice: React.FC<{
  data: PrismicSlice
  audienceId?: string
}> = ({ data, audienceId }) => {
  const googleAnalytics = useGoogleAnalytics()

  const { t } = useTranslation("forms")
  const subscribe = useSubscribe()
  const [isLoading, setLoading] = useState(false)
  const store = useStoreContext()
  const selectedCountry = store.isShoppingInEurope()
    ? ""
    : store.country.toUpperCase()

  const [isSignUpSuccessful, setSignUpSuccessful] = useState(false)

  const BaseCol = getBaseCol(data.primary.width)
  const textLayout = {
    ...processTextAlignment(
      BaseCol,
      data.primary.width,
      data.primary.text_alignment
    )
  }

  const mandrillTemplate = data.primary.mandrill_template
  const segmentId = data.primary.audience_tag?.id || data.primary.segment_id
  const finalAudienceId = data.primary.audience_id || audienceId
  const validateNewsletterSignup =
    data.primary.subscription_required === undefined ||
    data.primary.subscription_required

  function onSubmit(values: SubscriptionFormData, helpers) {
    setLoading(true)

    const utmData = extractUTMData(window.location.search)?.value
    const source = utmData?.source?.toLowerCase() || ""
    const tagName =
      source && SIGNUP_STORES.includes(source)
        ? `${STORE_SIGNUP_TAG},${source}`
        : ""

    return subscribe
      .subscribe({ ...values, tagName }, finalAudienceId)
      .catch(err => {
        setSignUpSuccessful(false)
        helpers.setStatus({ status: "error" })
        return Promise.reject(err)
      })
      .then(isSuccessful => {
        if (isSuccessful) {
          helpers.resetForm()
          setSignUpSuccessful(true)
          if (utmData) googleAnalytics.reportNewsLetterSignUp(utmData)
        } else {
          helpers.setStatus({ status: "error" })
          setSignUpSuccessful(false)
        }

        return isSuccessful
      })
      .finally(() => {
        helpers.setSubmitting(false)
        setLoading(false)
      })
  }

  return (
    <PrismicElementWrapper prismicStyle={data.primary} ignoreColorMode>
      <Column sx={{ ...textLayout }}>
        {isSignUpSuccessful && (
          <Box>
            <H5
              sx={{
                pt: 32,
                mb: 8,
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: "grey.3"
              }}
            >
              {t("newsLetterThankYou", "Thank you")}
            </H5>
            <H6 sx={{ color: "grey.1", mb: 48, maxWidth: "48ch" }}>
              {t("newsLetterThankYouMessage", {
                defaultValue:
                  "You have been successfully signed up. You should receive an mail with your discount code. "
              })}
            </H6>
          </Box>
        )}
        {!isSignUpSuccessful && (
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              emailAddress: "",
              country: selectedCountry,
              agreeCheckbox: false,
              signUpCheckbox: false,
              segmentId,
              audienceId,
              mandrillTemplate
            }}
            onSubmit={onSubmit}
            validationSchema={SubscriptionFormSchema(
              t,
              validateNewsletterSignup
            )}
          >
            {props => (
              <Form>
                {formStatus(props, "error") && (
                  <Box sx={{ marginBottom: 16 }}>
                    <_ErrorCard
                      title={t("formSubmitError")}
                      message={t("formSubmitErrorMessage")}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    "& > * + *": { mt: 8 }
                  }}
                >
                  <Grid columns={2} gap={8}>
                    <FirstName />
                    <LastName />
                  </Grid>
                  <Box>
                    <EmailAddress />
                  </Box>
                  <Box>
                    <Country />
                  </Box>

                  <Box sx={{ pt: 16 }}>
                    <SignupCheckBox />
                  </Box>
                  <Box sx={{ pt: 16, pb: 8 }}>
                    <AgreeCheckBox />
                  </Box>

                  <Box>
                    <PrimaryButton
                      type="submit"
                      fill={true}
                      loading={isLoading}
                      id="submitFormButton"
                    >
                      {t("submit", "Submit")}
                    </PrimaryButton>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        )}{" "}
      </Column>
    </PrismicElementWrapper>
  )
}

export const SubscriptionFormSlice: React.FC<{
  data: PrismicSlice
  audienceId?: string
}> = ({ data, audienceId }) => {
  const klaviyoSubscriptionForm = useKlaviyo()
  return klaviyoSubscriptionForm ? (
    <div className={klaviyoSubscriptionForm}></div>
  ) : (
    <MailchimpSubscriptionFormSlice data={data} audienceId={audienceId} />
  )
}

export const SponsorshipFormSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const textLayout = processTextAlignment(
    getBaseCol(data.primary.width),
    data.primary.width,
    data.primary.text_alignment
  )

  const { t } = useTranslation("forms")

  const [isLoading, setLoading] = useState(false)

  const store = useStoreContext()

  const selectedCountry = store.isShoppingInEurope()
    ? ""
    : store.country.toUpperCase()

  const sponsorshipForm = useSponsorship()

  const InitialSponsorshipValues: SponsorshipFormData = {
    name: "",
    emailAddress: "",
    phoneNumber: "",
    addressLineOne: "",
    addressLineTwo: "",
    country: selectedCountry,
    categories: {
      charity: false,
      education: false,
      sports: false,
      socialMatters: false,
      environmentalCulturalMatters: false,
      campaign: false,
      cooperation: false,
      other: false
    },
    typeOfGrant: {
      generalSponsorship: false,
      styrktarlina: false,
      logo: false,
      prizes: false,
      clothing: false,
      other: false
    },
    amount: "",
    description: ""
  }

  const onSubmit = (
    values: SponsorshipFormData,
    helpers: FormikHelpers<SponsorshipFormData>
  ) =>
    Promise.resolve(setLoading(true))
      .then(() => sponsorshipForm.submit(values))
      .catch(err => {
        helpers.setStatus({ status: "error" })
        return Promise.reject(err)
      })
      .then(resp => {
        if (resp.data.success) {
          helpers.resetForm()
          helpers.setStatus({ status: "success" })
          helpers.setValues(InitialSponsorshipValues, false)
        } else {
          helpers.setStatus({ status: "error" })
        }
      })
      .finally(() => setLoading(false))

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Column sx={{ ...textLayout }}>
        <Formik
          initialValues={InitialSponsorshipValues}
          validationSchema={SponsorshipFormSchema(t)}
          onSubmit={onSubmit}
        >
          {props => {
            return (
              <Form>
                {formStatus(props, "error") && (
                  <Box sx={{ marginBottom: 24 }}>
                    <_ErrorCard
                      title={t("formSubmitError")}
                      message={t("formSubmitErrorMessage")}
                    />
                  </Box>
                )}
                {formStatus(props, "success") && (
                  <Box sx={{ marginBottom: 24 }}>
                    <SuccessCard
                      title={t("contactUsSuccess")}
                      message={t("contactUsSuccessMessage")}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    "& > div + *": { mt: 8 }
                  }}
                >
                  <Box sx={{ mb: 12 }}>
                    <YourName />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <EmailAddress />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <PhoneNumber label="phoneNumber" />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <AddressLineOne fieldName="addressLineOne" />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <AddressLineTwo fieldName="addressLineTwo" />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <Country />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <SponsorshipCategories />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <GrantTypes />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <Amount />
                  </Box>
                  <Box sx={{ mb: 12 }}>
                    <Description />
                  </Box>
                  <PrimaryButton
                    type="submit"
                    fill={true}
                    loading={isLoading}
                    id="submitFormButton"
                  >
                    <SimpleTrans name="submit">Submit</SimpleTrans>
                  </PrimaryButton>
                </Box>
              </Form>
            )
          }}
        </Formik>
        <RecaptchaNotice />
      </Column>
    </PrismicElementWrapper>
  )
}

const Wrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <Flex sx={{ marginBottom: 16 }}>{children}</Flex>
}
// can we reuse the component so its in
//web/src/auth/components/RegistrationForm.tsx > PosturField and all other account forms?
export const AgreeCheckBox: React.FC = ({}) => {
  const [_, meta] = useField("agreeCheckbox")
  const { t } = useTranslation("forms")

  const PrivacyAndCookiePolicy: React.FC = ({}) => {
    return (
      <PrismicDocumentLink
        documentId={"XweMLBQAACMAnf94"}
        sx={{ variant: `links.${LinkVariants.standard}` }}
      >
        {t("privacyPolicy")}
      </PrismicDocumentLink>
    )
  }

  const label = (
    <Box sx={{ flex: 1 }}>
      <SimpleTrans
        i18nKey="forms:legalAccountConfirm"
        components={[<PrivacyAndCookiePolicy key={1} />]}
      >
        privacyAndCookiePolicy
      </SimpleTrans>
    </Box>
  )
  return (
    <Wrapper>
      <Field
        name="agreeCheckbox"
        as={Checkbox}
        hasError={!!meta.error && !!meta.touched}
        errorMessage={meta.error}
        label={label}
      />
    </Wrapper>
  )
}

export const SignupCheckBox: React.FC = ({}) => {
  const [_, meta] = useField("signUpCheckbox")
  const { t } = useTranslation("forms")

  const PrivacyAndCookiePolicy: React.FC = ({}) => {
    return (
      <PrismicDocumentLink
        documentId={"XweMLBQAACMAnf94"}
        sx={{ variant: `links.${LinkVariants.standard}` }}
      >
        {t("privacyPolicy")}
      </PrismicDocumentLink>
    )
  }
  const label = (
    <Box sx={{ flex: 1 }}>
      <SimpleTrans
        i18nKey="forms:legalNewsletterConfirm"
        components={[<PrivacyAndCookiePolicy key={1} />]}
      >
        privacyAndCookiePolicy
      </SimpleTrans>
    </Box>
  )
  return (
    <Wrapper>
      <Field
        name="signUpCheckbox"
        as={Checkbox}
        hasError={!!meta.error && !!meta.touched}
        errorMessage={meta.error}
        label={label}
      />
    </Wrapper>
  )
}

export const YourName: React.FC = ({}) => {
  const { t } = useTranslation("forms")

  const [_, meta] = useField("name")
  return (
    <Field
      name="name"
      as={TextInput}
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
    >
      {t("forms:yourName", { defaultValue: "yourName" })}
    </Field>
  )
}

export const FirstName: React.FC = ({}) => {
  const { t } = useTranslation("forms")

  const [_, meta] = useField("firstName")
  return (
    <Field
      name="firstName"
      as={TextInput}
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
    >
      {t("forms:firstName", { defaultValue: "firstName" })}
    </Field>
  )
}

export const LastName: React.FC = ({}) => {
  const [_, meta] = useField("lastName")
  const { t } = useTranslation("forms")

  return (
    <Field
      name="lastName"
      as={TextInput}
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
    >
      {t("forms:lastName", { defaultValue: "lastName" })}
    </Field>
  )
}

export const EmailAddress: React.FC<{ name?: string }> = ({
  name = "emailAddress"
}) => {
  const [_, meta] = useField(name)
  const { t } = useTranslation("forms")

  return (
    <Field
      name={name}
      as={EmailInput}
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
    >
      {t("forms:emailAddress", { defaultValue: "emailAddress" })}
    </Field>
  )
}

export const Message: React.FC = ({}) => {
  const [_, meta] = useField("message")
  const { t } = useTranslation("forms")

  return (
    <Field
      name="message"
      as={TextArea}
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
    >
      {t("forms:message", { defaultValue: "msg" })}
    </Field>
  )
}

export const Description: React.FC = ({}) => {
  const [_, meta] = useField("description")
  const { t } = useTranslation("forms")

  return (
    <Field
      name="description"
      as={TextArea}
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
    >
      {t("forms:description")}
    </Field>
  )
}

// translate

// Product question
// Returns
// Repairs
// Warranty
// Where is my delivery
// Wholesale
// Press inquiry
// Issue using the site
// Accessibilty
// Other
const inquiryReasons = {
  inquriyProductQuestion: "Product question",
  inquriyRepairs: "Repairs",
  inquriyWarranty: "Warranty",
  inquriyDelivery: "Where is my delivery",
  inquriyCorporate: "Corporate services",
  inquriyWholesale: "Wholesale",
  inquriyPress: "Press inquiry",
  inquriyUsingSite: "Issue using the site",
  inquriyAccessibilty: "Accessibilty",
  inquriyOther: "Other"
}

export const ReasonForInquiry: React.FC = ({}) => {
  const { t } = useTranslation("checkout")
  const [_, meta] = useField("inquiryReason")

  return (
    <Field
      name="inquiryReason"
      as={Select}
      id="inquiry-reason"
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
      label={t("forms:inquiryReason")}
    >
      <option disabled value="">
        –
      </option>
      {Object.keys(inquiryReasons).map(key => {
        return (
          <option value={key} key={key}>
            {t(`forms:${key}`)}
          </option>
        )
      })}
    </Field>
  )
}

export const PhoneNumber: React.FC<{ label?: string }> = ({
  label = "phoneNumberRequest"
}) => {
  const [_, meta] = useField("phoneNumber")
  const { t } = useTranslation("forms")

  return (
    <Field
      name="phoneNumber"
      as={TextInput}
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
    >
      {t(`forms:${label}`)}
    </Field>
  )
}

export const Amount: React.FC = ({}) => {
  const [_, meta] = useField("amount")
  const { t } = useTranslation("forms")
  return (
    <Field
      name="amount"
      as={TextInput}
      hasError={!!meta.error && !!meta.touched}
      errorMessage={meta.error}
    >
      {t("forms:amount")}
    </Field>
  )
}

export const SponsorshipCategories: React.FC = ({}) => {
  const formik = useFormikContext<{ categories: string }>()
  const { t } = useTranslation("forms")

  return (
    <Column>
      <H6 sx={{ color: "black", mb: 16 }}>
        {t("categories", { defaultValue: "Categories" })}
      </H6>
      <SponsorshipCategory name="charity" />
      <SponsorshipCategory name="education" />
      <SponsorshipCategory name="sports" />
      <SponsorshipCategory name="business" />
      <SponsorshipCategory name="socialMatters" />
      <SponsorshipCategory name="environmentalCulturalMatters" />
      <SponsorshipCategory name="campaign" />
      <SponsorshipCategory name="cooperation" />
      <SponsorshipCategory name="other" />
      {formik.errors && formik.errors.categories && (
        <FormError sx={{ mt: 0 }}>{formik.errors.categories}</FormError>
      )}
    </Column>
  )
}

export const SponsorshipCategory: React.FC<{ name: string }> = ({ name }) => {
  const [_, meta] = useField(`categories.${name}`)
  const { t } = useTranslation("forms")
  return (
    <Box sx={{ mb: 16 }}>
      <Field
        name={`categories.${name}`}
        as={Checkbox}
        hasError={!!meta.error && !!meta.touched}
        errorMessage={meta.error}
        id={`categories.${name}`}
        label={t(name)}
      />
    </Box>
  )
}

export const GrantTypes: React.FC = ({}) => {
  const formik = useFormikContext<{ typeOfGrant: string }>()
  const { t } = useTranslation("forms")

  return (
    <Column>
      <H6 sx={{ color: "black", mb: 16 }}>
        {t("typeOfGrant", { defaultValue: "Type of grant" })}
      </H6>
      <GrantType name="generalSponsorship" />
      <GrantType name="styktarlina" />
      <GrantType name="logo" />
      <GrantType name="prizes" />
      <GrantType name="clothing" />
      <GrantType name="other" />
      {formik.errors && formik.errors.typeOfGrant && (
        <FormError sx={{ mt: 0 }}>{formik.errors.typeOfGrant}</FormError>
      )}
    </Column>
  )
}

export const GrantType: React.FC<{ name: string }> = ({ name }) => {
  const [_, meta] = useField(`typeOfGrant.${name}`)
  const { t } = useTranslation("forms")
  return (
    <Box sx={{ mb: 16 }}>
      <Field
        name={`typeOfGrant.${name}`}
        as={Checkbox}
        hasError={!!meta.error && !!meta.touched}
        errorMessage={meta.error}
        id={`typeOfGrant.${name}`}
        label={t(name)}
      />
    </Box>
  )
}

interface ContactUsFormData {
  name: string
  emailAddress: string
  phoneNumber: string
  message: string
  inquiryReason: string
  country: string
}

export const ContactUsFormSchema = (t: (key: string) => string) => {
  return Yup.object().shape({
    name: Yup.string().required(t("forms:nameRequired")),
    emailAddress: Yup.string()
      .email(t("validEmailRequired"))
      .required(t("emailRequired")),
    phoneNumber: Yup.string().notRequired(),
    inquiryReason: Yup.string()
      .oneOf(Object.keys(inquiryReasons))
      .required(t(t("enquiryReasonRequired"))),
    country: Yup.string().required(t("countryRequired")),
    message: Yup.string().required(t("messageRequired"))
  })
}

interface SubscriptionFormData {
  firstName: string
  lastName: string
  emailAddress: string
  country: string
  signUpCheckbox: boolean
  agreeCheckbox: boolean
}

export const SubscriptionFormSchema = (
  t: (key: string) => string,
  validateNewsletterSignup: boolean
) => {
  return Yup.object().shape({
    firstName: Yup.string().required(t("forms:firstNameRequired")),
    lastName: Yup.string().required(t("forms:lastNameRequired")),
    emailAddress: Yup.string()
      .email(t("forms:validEmailRequired"))
      .required(t("forms:emailRequired")),
    country: Yup.string().required(t("forms:countryRequired")),
    signUpCheckbox: validateNewsletterSignup
      ? Yup.bool().oneOf([true], t("forms:signUpRequired"))
      : Yup.bool().notRequired(),
    agreeCheckbox: Yup.bool().oneOf([true], t("forms:agreeRequired"))
  })
}

export const SponsorshipFormSchema = (t: (key: string) => string) => {
  return Yup.object().shape({
    name: Yup.string().required(t("forms:nameRequired")),
    emailAddress: Yup.string()
      .email(t("forms:validEmailRequired"))
      .required(t("forms:emailRequired")),
    phoneNumber: Yup.string().required(t("forms:phoneRequired")),
    addressLineOne: Yup.string(),
    addressLineTwo: Yup.string(),
    country: Yup.string().required(t("forms:countryRequired")),
    categories: Yup.object()
      .shape({
        charity: Yup.boolean(),
        education: Yup.boolean(),
        sports: Yup.boolean(),
        business: Yup.boolean(),
        socialMatters: Yup.boolean(),
        environmentalCulturalMatters: Yup.boolean(),
        campaign: Yup.boolean(),
        other: Yup.boolean()
      })
      .test("validateCategories", "", obj => {
        if (Object.values(obj).filter(it => it).length > 0) {
          return true
        }
        return new Yup.ValidationError(
          t("forms:pleaseChooseCategory"),
          null,
          "categories"
        )
      }),
    typeOfGrant: Yup.object()
      .shape({
        generalSponsorship: Yup.boolean(),
        styrktarlina: Yup.boolean(),
        logo: Yup.boolean(),
        prizes: Yup.boolean(),
        clothing: Yup.boolean(),
        other: Yup.boolean()
      })
      .test("validateTypeOfGrant", "", obj => {
        if (Object.values(obj).filter(it => it).length > 0) {
          return true
        }
        return new Yup.ValidationError(
          t("forms:pleaseChooseGrantType"),
          null,
          "typeOfGrant"
        )
      }),
    amount: Yup.string(),
    description: Yup.string().required(t("descriptionRequired"))
  })
}
