import React from "react"
import { TextAndLinksBanner } from "@sixty-six-north/ui-system"
import { PrismicSlice } from "../PrismicModels"
import { useRelatedContentBlocks } from "prismic/PrismicRelatedContentBlocksContext"
import { useStoreContext } from "i18n/StoreHooks"
import { countryToPath } from "i18n/Country"

export const RelatedContentBlockSlice: React.FC<{
  data: PrismicSlice
}> = ({ data }) => {
  const { country } = useStoreContext()
  const { relatedContentBlocks } = useRelatedContentBlocks()
  const blockId = data.primary.related_content_block?.id
  const blockData = blockId
    ? relatedContentBlocks.find(b => b.id === blockId)
    : null

  if (!blockData) return null

  const { data: { title = [], description = [], links = [] } = {} } = blockData
  const titleText = title.length > 0 ? title[0].text : ""
  const descriptionText = description.length > 0 ? description[0].text : ""
  const linksData = links
    .filter(({ link }) => link.data?.title && link.data.path)
    .map(({ link }) => {
      return {
        title: link.data?.title ? link.data?.title[0].text : "",
        href: `/${countryToPath(country) + link.data?.path}`
      }
    })

  return (
    <TextAndLinksBanner
      title={titleText}
      text={descriptionText}
      links={linksData}
    />
  )
}
