import {
  useStoreContext,
  useStoreFromCookie,
  useStoreFromGeography
} from "i18n/StoreHooks"
import { DEFAULT_REGION, REGIONS } from "i18n/Region"

export const useStoreName = () => {
  // Constructs a store name based on the current store the user is in.
  // Logic originates from the messagebar functions
  const storeCountry = useStoreContext().country
  const storeFromCookie = useStoreFromCookie()
  const storeFromGeography = useStoreFromGeography()
  const store = useStoreContext()

  const defaultValue =
    storeCountry ||
    storeFromCookie?.country ||
    storeFromGeography?.country ||
    DEFAULT_REGION.country

  return defaultValue.toUpperCase()
}
