//jsxhook

import {
  ArticleList,
  ArticleListItem,
  ImgIXRatio
} from "@sixty-six-north/ui-system"

import { RichText } from "prismic-reactjs"
import { Flex } from "theme-ui"
import { useRouter } from "../../routing/useRouter"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import {
  usePrismicLinks,
  usePrismicLinkedDocuments
} from "../PrismicLinkedDocumentContext"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { PrismicImgixImage } from "./PrismicImgixImage"
import { PrismicDocumentLink, PrismicLink } from "./PrismicLink"
import React from "react"
import { useTranslation } from "react-i18next"

export const ArticleListSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const primaryAdapter = new PrismicPrimaryAdapter(data.primary)
  const links = usePrismicLinks()
  const { t } = useTranslation("layout")
  const linkedDocuments = usePrismicLinkedDocuments()
  const router = useRouter()

  const extractArticleInformationForItem = item => {
    const adapter = new PrismicItemAdapter(item)
    let articleTitle = adapter.cardTitle("")
    let articleText =
      item.card_text && item.card_text[0]?.text ? item.card_text : undefined
    let imageNode =
      item.image && Object.keys(item.image).length > 0 ? item.image : undefined
    const articleDocument1 = linkedDocuments.find(d => d.id === item.content.id)
    const articleDocument2 = linkedDocuments.find(d => d.id === item.link.id)

    const mainArticleDocument = articleDocument1 || articleDocument2
    const linkLabel =
      adapter.linkLabel("") ||
      mainArticleDocument?.data?.link_label ||
      t("viewMore")

    if (mainArticleDocument && mainArticleDocument.data) {
      const {
        meta_title,
        meta_list_description,
        thumbnail,
        article_thumbnail
      } = mainArticleDocument.data
      articleTitle = articleTitle || (meta_title && meta_title[0]?.text) || ""
      articleText = articleText || meta_list_description
      imageNode =
        imageNode || (!!article_thumbnail?.url ? article_thumbnail : thumbnail)
    }

    return {
      articleTitle,
      articleText,
      linkLabel,
      imageNode
    }
  }

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <ArticleList
        title={primaryAdapter.article_list_title("")}
        countLabel={primaryAdapter.count_label("")}
      >
        {data.items.map((item, idx) => {
          const { articleTitle, articleText, linkLabel, imageNode } =
            extractArticleInformationForItem(item)
          const adapter = new PrismicItemAdapter(item)
          const tags = adapter
            .tags("")
            .split(",")
            .filter(item => item.length > 0)
            .map(item => ({ label: item }))

          let down: number
          let up: number

          let Link: React.ReactElement = <React.Fragment />
          if (item.content && item.content.id)
            Link = (
              <PrismicDocumentLink documentId={item.content.id}>
                {linkLabel}
              </PrismicDocumentLink>
            )
          if (item.link && item.link.url)
            Link = <PrismicLink href={item.link.url}>{linkLabel}</PrismicLink>

          return (
            <Flex
              className={"article-list-item"}
              key={idx}
              onMouseDown={() => (down = +new Date())}
              onMouseUp={ev => {
                ev.preventDefault()
                up = +new Date()
                if (up - down < 200) {
                  if (item.content && item.content.uid) {
                    if (item.content && item.content.id) {
                      const link = links.linkForDocumentId(item.content.id)
                      if (link) {
                        router.push(link.href, link.as)
                      }
                    }
                  }
                  if (item.link && item.link.url) {
                    if (item.link.target && item.link.target === "_blank") {
                      window.open(item.link.url, "_blank")
                    } else {
                      router.push(item.link.url)
                    }
                  }
                }
              }}
            >
              <ArticleListItem
                title={articleTitle}
                text={articleText && RichText.render(articleText)}
                additional_label={adapter.additional_label("")}
                imageAlt={""}
                tags={tags}
                imageNode={
                  imageNode && Object.keys(imageNode).length > 0 ? (
                    <PrismicImgixImage
                      image={imageNode}
                      sizes={"10vw"}
                      aspectRatio={ImgIXRatio(primaryAdapter.cardRatio("4:3"))}
                    />
                  ) : null
                }
                link={Link}
              />
            </Flex>
          )
        })}
      </ArticleList>
    </PrismicElementWrapper>
  )
}
