//jsxhook

import React, { ReactNode } from "react"
import { FilterService } from "./FilterService"

// @ts-ignore
export const FilterContext: React.Context<FilterService> =
  // @ts-ignore
  React.createContext<FilterService>(undefined)

export const FilterProvider: React.FC<{
  children?: ReactNode
}> = ({ children }) => {
  const filters = new FilterService()
  return (
    <FilterContext.Provider value={filters}>{children}</FilterContext.Provider>
  )
}
