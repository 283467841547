import {
  HeadingRegularVariants,
  ImgIXRatio,
  TextVariants
} from "@sixty-six-north/ui-system"
import { AnimatePresence, m } from "framer-motion"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "prismic/htmlSerializer"
import {
  PrismicItemAdapter,
  PrismicPrimaryAdapter
} from "prismic/PrismicAdapters"
import { usePrismicLinks } from "prismic/PrismicLinkedDocumentContext"
import { linkResolver } from "prismic/PrismicLinks"
import { PrismicSlice } from "prismic/PrismicModels"
import { useRef, useState } from "react"
import { Box, Flex } from "theme-ui"
import { PrismicElementWrapper } from "./Layout"
import { PrismicImgixImage } from "./PrismicImgixImage"
interface Props {
  data: PrismicSlice
}
export function TabContentViewer({ data }: Props) {
  const primaryAdapter = new PrismicPrimaryAdapter(data.primary)
  const links = usePrismicLinks()

  const [currentItem, setCurrentItem] = useState(0)
  const humanTotal = data.items.length
  const TabListRef = useRef<HTMLDivElement>(null)
  const TabItemRef = useRef<HTMLDivElement>(null)

  const handleChangeYear = (yearIndex: number) => {
    setCurrentItem(yearIndex)
    TabListRef?.current?.setAttribute("aria-live", "polite")
    TabItemRef?.current?.focus()
  }
  const handleBlur = () => {
    TabListRef?.current?.setAttribute("aria-live", "off")
  }

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Flex
        sx={{
          flexDirection: ["column", null, null, "row"],
          width: "100%",
          minHeight: "40vh",
          height: "auto",
          gap: 48,
          justifyContent: "flex-start"
        }}
      >
        <Flex ref={TabListRef} aria-live="polite" sx={{ flex: 1 }}>
          <AnimatePresence mode={"wait"} initial>
            {data.items.map((item, index) => {
              const adapter = new PrismicItemAdapter(item)
              const humanIndex = index + 1

              return (
                currentItem === index && (
                  <m.div
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1
                    }}
                    exit={{
                      opacity: 0
                    }}
                    ref={TabItemRef}
                    key={`tab-${index}`}
                    id={`tab-item-${humanIndex}`}
                    role="tabpanel"
                    aria-roledescription="slide"
                    aria-label={humanIndex + " of " + humanTotal}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: [16, null, null, 48],
                      flex: 1,
                      width: "100%"
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        width: "100%",
                        aspectRatio: "3/4",
                        position: "relative",
                        display: "flex",
                        overflow: "hidden",
                        borderRadius: 3,
                        "&  img": {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover"
                        }
                      }}
                    >
                      <PrismicImgixImage
                        image={adapter.image()}
                        sizes={"50vw"}
                        aspectRatio={ImgIXRatio(
                          primaryAdapter.cardRatio("3:4")
                        )}
                      />
                    </Box>
                    <Flex
                      sx={{
                        flexDirection: "column",
                        maxWidth: "40ch"
                      }}
                    >
                      <h6
                        sx={{ variant: `text.${HeadingRegularVariants.sixth}` }}
                        className="color-mode-title"
                      >
                        {adapter.label()}
                      </h6>
                      <p
                        sx={{
                          variant: `text.${TextVariants.technicalParagraphSmall}`
                        }}
                      >
                        <RichText
                          render={item.value}
                          linkResolver={linkResolver(links)}
                          htmlSerializer={htmlSerializer(
                            links,
                            data.primary.mark_as_h1
                          )}
                        />
                      </p>
                    </Flex>
                  </m.div>
                )
              )
            })}
          </AnimatePresence>
        </Flex>
        <Box sx={{ ml: "auto", overflow: "hidden" }}>
          <Flex
            sx={{
              flexDirection: ["row", null, null, "column"],
              gap: 24,
              color: data.primary.color_mode === "dark" ? "white.0" : "black.1",
              justifyContent: "center",
              scrollSnapType: "x mandatory",
              width: ["100%", null, null, "auto"],
              overflow: "overlay",
              height: "100%"
            }}
            role="tablist"
            aria-label="Years"
          >
            {data.items
              .map(it => new PrismicItemAdapter(it))
              .map((item, index) => {
                const humanIndex = index + 1
                return (
                  <Box
                    as="button"
                    key={`tab-control-${index}`}
                    id={`tab-${humanIndex}`}
                    role="tab"
                    aria-label={` ${item.year()}`}
                    aria-selected={index === currentItem ? true : false}
                    tabIndex={currentItem === index ? -1 : undefined}
                    aria-controls={`tab-item-${humanIndex}`}
                    onClick={() => handleChangeYear(index)}
                    onMouseEnter={() => handleChangeYear(index)}
                    onBlur={() => handleBlur()}
                    sx={{
                      margin: 0,
                      padding: 0,
                      width: "auto",
                      display: "inline-flex",
                      minWidth: "4ch",
                      bg: "inherit",
                      border: "none",

                      color:
                        data.primary.color_mode === "dark"
                          ? index === currentItem
                            ? "white.0"
                            : "grey.1"
                          : index === currentItem
                            ? "black.1"
                            : "grey.2",
                      variant: `text.${HeadingRegularVariants.sixth}`,
                      scrollSnapAlign: "center"
                    }}
                  >
                    {item.year()}
                  </Box>
                )
              })}
          </Flex>
        </Box>
      </Flex>
    </PrismicElementWrapper>
  )
}
