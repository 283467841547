//jsxhook

import {
  HorizontalDivider,
  LinkVariants,
  TechnicalParagraph
} from "@sixty-six-north/ui-system"
import { Trans, useTranslation } from "react-i18next"
import { Box } from "theme-ui"

export const RecaptchaNotice: React.FC = props => {
  const { t } = useTranslation("layout")

  const PrivacyLink: React.FC = ({}) => {
    return (
      <a
        href="https://policies.google.com/privacy"
        sx={{ variant: `links.${LinkVariants.standard}` }}
      >
        {t("privacyPolicy")}
      </a>
    )
  }

  const TOSLink: React.FC = ({}) => {
    return (
      <a
        href="https://policies.google.com/terms"
        sx={{ variant: `links.${LinkVariants.standard}` }}
      >
        {t("tos")}
      </a>
    )
  }

  return (
    <Box sx={{ mt: 24 }}>
      <HorizontalDivider sx={{ mb: 24 }} />
      <TechnicalParagraph sx={{ color: "grey.0" }}>
        <Trans
          i18nKey="layout:recaptchaMessage"
          components={[<PrivacyLink key="privacyy" />, <TOSLink key="tos" />]}
        ></Trans>
      </TechnicalParagraph>
    </Box>
  )
}
