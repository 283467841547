//jsxhook

import {
  Fonts,
  FontWeight,
  LineHeights,
  TextVariants
} from "@sixty-six-north/ui-system"
import { ThemeUIStyleObject } from "@theme-ui/css"
import { RichText } from "prismic-reactjs"
import React from "react"
import { Box, ThemeUICSSObject } from "theme-ui"
import { htmlSerializer } from "../htmlSerializer"
import { usePrismicLinks } from "../PrismicLinkedDocumentContext"
import { linkResolver } from "../PrismicLinks"
import {
  PrismicSlice,
  PrismicSliceWidth,
  PrismicTextAlignment,
  PrismicTextStyle
} from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"

// {RichText.render(document.data.title, linkResolver)}
// {RichText.render(document.data.body[0].primary.text, linkResolver)}

//data.primary.text_alignment
// text alignment styles
const gridSpacing = [0, null, null, 24, "half-56"]

export const getBaseCol = (width: PrismicSliceWidth) => {
  switch (width) {
    case "full":
      return 16
    case "standard":
      return 16
    case "half_left":
      return 8
    case "half_right":
      return 8
    default:
      return 16
  }
}

export const textLayoutCenter = (BaseCol: number) => {
  return {
    width: [
      "100%",
      null,
      null,
      `${(7 / BaseCol) * 100}%`,
      `${(6 / BaseCol) * 100}%`
    ],
    mx: "auto"
  }
}

export const textLayoutLeft = (
  BaseCol: number,
  prismicWidth: PrismicSliceWidth,
  textStyle?: PrismicTextStyle
): ThemeUIStyleObject => {
  return {
    width:
      textStyle === "deck"
        ? BaseCol < 16
          ? "100%"
          : ["100%", null, null, `${(11 / BaseCol) * 100}%`]
        : [
            "100%",
            null,
            null,
            `${(7 / BaseCol) * 100}%`,
            `${(6 / BaseCol) * 100}%`,
            `${(5 / BaseCol) * 100}%`
          ],
    clear: "left",
    float: "left",
    ml: prismicWidth === "half_right" ? gridSpacing : 0,

    mr: "auto",
    display: "block",
    verticalAlign: "top",
    maxWidth: [
      "100vw",
      null,
      null,
      null,
      textStyle === "deck" ? "52ch" : "48ch"
    ]
  }
}

// || data.primary.width === "half_right" && data.primary.text_alignment === "right"
export const textLayoutRight = (
  BaseCol: number,
  prismicWidth: PrismicSliceWidth,
  prismicAlignment: PrismicTextAlignment
): ThemeUIStyleObject => {
  return {
    width: [
      "100%",
      null,
      null,
      `${(7 / BaseCol) * 100}%`,
      `${(6 / BaseCol) * 100}%`,
      `${(5 / BaseCol) * 100}%`
    ],
    clear: ["none", null, null, "right"],
    float: ["left", null, null, "right"],
    pr: gridSpacing, // fix for aligning half right elements
    ml:
      prismicWidth === "half_right" && prismicAlignment === "left"
        ? gridSpacing
        : [null, null, null, "auto"],
    display: "block",
    verticalAlign: "top",
    maxWidth: ["100vw", null, null, null, "30vw"]
  }
}

// what styles to use
export const processTextAlignment = (
  BaseCol: number,
  textWidth: PrismicSliceWidth,
  textAlignment?: PrismicTextAlignment,
  textStyle?: PrismicTextStyle
): ThemeUIStyleObject => {
  switch (textAlignment) {
    case "center":
      return textLayoutCenter(BaseCol)
    case "right":
      return textLayoutRight(BaseCol, textWidth, textAlignment)
    case "left":
      return textLayoutLeft(BaseCol, textWidth, textStyle)
    default:
      return textLayoutLeft(BaseCol, textWidth, textStyle)
  }
}

export const TextSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const links = usePrismicLinks()

  const BaseCol = getBaseCol(data.primary.width)

  const textLayoutColumn2: ThemeUIStyleObject = {
    width: ["100%", null, null, `${(12 / BaseCol) * 100}%`],
    columnCount: [1, null, null, 2],
    columnFill: "balance",
    columnGap: 96,

    mx: "auto",
    "h1,h2,h3,h4,h5,h6, ul, ol, li, pre, code, p": {
      width: "100%",
      columnBreakInside: "avoid"
    }
  }

  const processTextStyle = (ts?: PrismicTextStyle) => {
    switch (ts) {
      case "editorial_large":
        return `text.${TextVariants.editorialParagraphLarge}`
      case "technical_large":
        return `text.${TextVariants.technicalParagraphLarge}`
      case "technical_small":
        return `text.${TextVariants.technicalParagraphSmall}`
      case "brand_voice":
        return `text.${TextVariants.technicalParagraphLarge}`
      case "deck":
        return `text.${TextVariants.deck}`
      default:
        return `text.${TextVariants.technicalParagraphLarge}`
    }
  }
  const textStyle = processTextStyle(data.primary.text_style)

  // main styles
  const processVerticalAlignment = (
    ts = data.primary.vertically_center
  ): ThemeUIStyleObject => {
    switch (ts) {
      case true:
        return {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1
        }
      case false:
        return {}

      default:
        return {}
    }
  }
  const textLayout = {
    ...processTextAlignment(
      BaseCol,
      data.primary.width,
      data.primary.text_alignment,
      data.primary.text_style
    )
  }

  const layoutOverrides: ThemeUIStyleObject =
    data.primary.text_alignment === "column-2"
      ? textLayoutColumn2
      : { width: "100%" }

  const imageLayoutLeft: ThemeUIStyleObject = {
    ml: [0, null, null, `${(1 / BaseCol) * 100}%`],
    float: ["left", null, null, "right"]
  }

  const imageLayoutRight: ThemeUIStyleObject = {
    ml: 0,
    mr: "auto",
    float: ["left", null, null, "left"],
    textAlign: "right"
  }
  const imageLayoutCenter: ThemeUIStyleObject = {
    ml: "auto",
    mr: "auto",
    float: ["none"],
    width: [
      "100%",
      null,
      null,
      `${(7 / BaseCol) * 100}%`,
      `${(6 / BaseCol) * 100}%`
    ]
  }

  const processImageAlignment = (ts = data.primary.text_alignment) => {
    switch (ts) {
      case "center":
        return imageLayoutCenter
      case "right":
        return imageLayoutRight
      case "left":
        return imageLayoutLeft
      default:
        return imageLayoutLeft
    }
  }

  const imageLayout: ThemeUIStyleObject = {
    position: "relative",
    display: "block",
    clear: "both",
    width: [
      "100%",
      null,
      null,
      `${(7 / BaseCol) * 100}%`,
      `${(8 / BaseCol) * 100}%`,
      `${(9 / BaseCol) * 100}%`
    ],
    ...processImageAlignment()
  }
  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box
        id={data.primary.anchor_id}
        className={data.primary.text_alignment}
        sx={{
          "& > *:not(style) + p": {
            mt: [16, 24]
          },
          "& > .styled-h2 + p": {
            mt: [16, 24]
          },

          "& > .styled-h3 + p": {
            mt: [16, 16]
          },

          "& > .styled-h4 + p": {
            mt: [8, 8]
          },
          "& > .styled-h5 + p": {
            mt: [8, 8]
          },
          "& > .styled-h6 + p": {
            mt: [8, 8]
          },
          "& > *:not(style) + ul": {
            mt: [16, 24]
          },

          "& > *:not(style) + ol": {
            mt: [16, 24]
          },

          "& > *:not(style) + .styled-h1": {
            mt: [16, 32]
          },
          "& > *:not(style) + .styled-h2": {
            mt: [16, 32]
          },
          "& > *:not(style) + .styled-h3": {
            mt: [16, 32]
          },
          "& > *:not(style) + .styled-h4": {
            mt: [16, 32]
          },
          "& > *:not(style) + .styled-h5": {
            mt: [16, 32]
          },
          "& > *:not(style) + .styled-h6": {
            mt: [16, 32]
          },
          "& > .styled-h1 + .block-img": {
            mt: [16, 24]
          },

          "& > p + .block-img": {
            mt: [16, 24]
          },
          "& > .block-img + p": {
            mt: [0, 0]
          },

          "& > p + .block-img + p": {
            mt: [0, 24]
          },

          "& > .block-img + .styled-h1": {
            mt: 0
          },
          "& > .block-img + .styled-h2": {
            mt: 0
          },
          "& > .block-img + .styled-h3": {
            mt: [16, null, null, 0]
          },
          "& > .block-img + .styled-h4": {
            mt: 0
          },
          "& > .block-img + .styled-h5": {
            mt: 0
          },
          "& > .block-img + .styled-h6": {
            mt: 0
          },

          "&.center > .block-img + .styled-h1": {
            mt: 16
          },
          "&.center > .block-img +.styled- h2": {
            mt: 16
          },
          "&.center > .block-img + .styled-h3": {
            mt: 16
          },
          "&.center > .block-img + .styled-h4": {
            mt: 16
          },
          "&.center > .block-img + .styled-h5": {
            mt: 16
          },
          "&.center > .block-img + .styled-h6": {
            mt: 16
          },

          "& > .styled-h1 + .block-img + p": {
            mt: [16, 24]
          },
          "& > .styled-h2 + .block-img + p": {
            mt: [16, 24]
          },
          "& > .styled-h3 + .block-img + p": {
            mt: [16, 24]
          },

          "& > .styled-h1 + .styled-h4": {
            mt: 8
          },

          "& > .styled-h6 + .styled-h1": {
            mt: 4
          },
          "& > .styled-h6 + .styled-h2": {
            mt: 4
          },
          "& > .styled-h6 + .styled-h3": {
            mt: 4
          },
          "& > .styled-h6 + .styled-h4": {
            mt: 4
          },
          "& > .styled-h6 + .styled-h5": {
            mt: 4
          },

          h1: {
            ...textLayout
          },
          h2: {
            ...textLayout
          },
          h3: {
            ...textLayout
          },
          h4: {
            ...textLayout
          },
          h5: {
            ...textLayout
          },
          h6: {
            ...textLayout,
            fontFamily: Fonts.text,
            fontWeight: FontWeight.textBold,
            lineHeight: LineHeights.editorial
          },
          p: {
            variant: textStyle,
            //override the h6 variant style for more line height
            ...textLayout
          },
          ul: {
            variant: textStyle,
            ...textLayout
          },
          ol: {
            variant: textStyle,
            ...textLayout
          },
          "ul > li + li": {
            mt: 8
          },
          "ol > li + li": {
            mt: 8
          },
          ".block-img": {
            mb: [24, 0],
            ...imageLayout,
            maxWidth: "100%"
          },

          img: {
            width: "100%",
            height: "auto",
            position: "sticky",
            top: 0
          },
          ".inset-image": {
            textAlign: "center"
          },
          ".inset-image img": {
            width: ["80%", null, "48%"]
          },

          "&.column-2 p": {
            mt: 0,
            mb: 16
          },

          ...processVerticalAlignment(),
          ...layoutOverrides
        }}
      >
        <RichText
          render={data.primary.the_text}
          linkResolver={linkResolver(links)}
          htmlSerializer={htmlSerializer(links, data.primary.mark_as_h1)}
        />
      </Box>
    </PrismicElementWrapper>
  )
}
