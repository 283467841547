//jsxhook
import { Box } from "theme-ui"
import React from "react"
import { AudioPlayer } from "@sixty-six-north/ui-system"
import { usePreferredLanguage } from "i18n/StoreHooks"
import { getBaseCol } from "."
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"

export const AudioPlayerSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const language = usePreferredLanguage()
  const BaseCol = getBaseCol(data.primary.width)

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box
        sx={{
          width: [
            "100%",
            null,
            null,
            `${(7 / BaseCol) * 100}%`,
            `${(6 / BaseCol) * 100}%`
          ],
          mx: "auto"
        }}
      >
        {data.items && (
          <AudioPlayer
            tracks={data.items}
            lang={language === "is" ? "is" : "en"}
          />
        )}
      </Box>
    </PrismicElementWrapper>
  )
}
