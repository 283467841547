//jsxhook
import {
  ArtDirectedImage,
  HeadingRegularVariants,
  ImageVariants,
  ImgixAspectRatio,
  ImgixFit,
  ImgixImage,
  ProductCard as CoreProductCard,
  ProductCardVariant,
  TextVariants
} from "@sixty-six-north/ui-system"
import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAllCategoriesLookup } from "../../category/CategoryHooks"
import { useProductListing } from "../../category/ProductListing"
import Link from "../../components/Link"
import { useProductProjectionLink, ProductUri } from "../../components/Links"
import { formatCurrency } from "../../i18n/CurrencyFormatter"
import { usePreferredLanguage, useStoreContext } from "../../i18n/StoreHooks"
import { getProductImagesForView } from "../../utils/ProductUtils"
import { useIsMobile } from "../../utils/uiHelpers"
import { assetsTaggedFor } from "../AssetsTaggedFor"
import { DomainCategory } from "../models/DomainCategory"
import {
  DetailedProductInformation,
  CoreProductInformation
} from "../models/DetailedProductInformation"
import { HierarchicalProductProxy, ColorwayProxy } from "../ProductPage"

interface Props {
  product: CoreProductInformation
  categorySlug?: string
  index?: number
  lazyload?: boolean
  subCategorySlug?: string
  color?: string
  filterColors?: string[]
  badge?: string
  border?: boolean
  tagSlug?: string | null
  imageRatio?: ImageVariants
  productCardVariant?: ProductCardVariant
  parentCategories?: DomainCategory[]
}
const ProductCard: FC<Props> = ({
  product,
  categorySlug,
  subCategorySlug,
  tagSlug,
  filterColors,
  color,
  index = 0,
  lazyload = true,
  badge,
  border = true,
  imageRatio,
  productCardVariant = ProductCardVariant.ORIGINAL,
  parentCategories
}) => {
  const { t } = useTranslation()
  const { currency } = useStoreContext()
  const language = usePreferredLanguage()
  const isMobile = useIsMobile()
  const imageView = useProductListing()?.imageView
  const redesignFlag = productCardVariant === ProductCardVariant.EXP_OCT_22

  const proxy = new HierarchicalProductProxy(
    product as DetailedProductInformation
  )

  const categoryLookup = useAllCategoriesLookup()

  const currentCategorySlug =
    categorySlug ||
    categoryLookup.categoryLookup(product.categoryKeys[0])?.slug[language] ||
    ""

  // removed this to make search work subCategories[0]?.obj?.slug[region.language] ||
  const currentSubCategorySlug = subCategorySlug || ""

  const mapToProductCardColorwayProps = (c: ColorwayProxy) => {
    const matchingImages = c.assets.filter(
      assetsTaggedFor(parentCategories || [])
    )

    const { imageUrl, hoverImageUrl } = getProductImagesForView(
      imageView,
      matchingImages
    )
    return {
      imageUrl,
      hoverImageUrl: !isMobile ? hoverImageUrl : "",
      imageAlt: product.listingDescription || "",
      colorName: c.name(),
      colorHex: c.hexcode,
      colorCode: c.colorCode,
      colorTerm: c.term,
      isInStock: true,
      price: c
        .price(currency)
        .map(price => formatCurrency(currency)(price))
        .getOrElse(""),
      discountedPrice: c
        .discountedPrice(currency)
        .map(price => formatCurrency(currency)(price))
        .getOrElse(""),
      index: c.index
    }
  }
  const colorways = proxy.colorways.map(mapToProductCardColorwayProps)

  // set a default color based on prop or use the first
  const colorwaysList = colorways.sort(
    (left, right) => left.index - right.index
  )

  const preferredColorway: string | undefined =
    color ||
    proxy.colorwaysFilteredByTerms(filterColors)?.colorCode ||
    proxy.defaultColorway.colorCode

  const [colorway, setColorway] = useState(preferredColorway)

  const link = useProductProjectionLink(
    currentCategorySlug,
    currentSubCategorySlug,
    new ProductUri(product.slug, product.key),
    colorway,
    tagSlug
  )

  useEffect(() => {
    const colorMatch = proxy.colorwaysFilteredByTerms(filterColors)
    if (colorMatch) {
      setColorway(colorMatch.colorCode)
    } else {
      setColorway(preferredColorway)
    }
  }, [JSON.stringify(filterColors), imageView])

  if (proxy.hasNoColorways) {
    return <React.Fragment />
  }

  return (
    <CoreProductCard
      id={`product-card-${product.key}`}
      index={index}
      key={`product-${product.key}-${categorySlug}`}
      border={border}
      translations={{
        color: t("product:color")
      }}
      name={product.name}
      description={product.listingDescription || product.description}
      selectedColorway={mapToProductCardColorwayProps(proxy.colorway(colorway))}
      setSelection={setColorway}
      colorways={colorwaysList}
      badge={badge}
      link={args => (
        <Link
          {...link}
          href={link.as}
          sx={{
            color: "grey.0",
            textDecoration: "none",
            variant: redesignFlag
              ? `text.${TextVariants.label2Bold}`
              : `text.${HeadingRegularVariants.sixth}`
          }}
        >
          {args.name}
        </Link>
      )}
      url={link.as}
      image={args => (
        <Link {...link} href={link.as} tabIndex={-1} aria-hidden>
          <ArtDirectedImage
            key={args.imageUrl}
            imageAlt={args.imageAlt}
            sx={{
              borderRadius: redesignFlag ? 3 : undefined
            }}
            imageNode={
              <ImgixImage
                imageAlt={args.imageAlt}
                imageUrl={args.imageUrl}
                aspectRatio={
                  redesignFlag ? ImgixAspectRatio.ar4x3 : ImgixAspectRatio.ar3x4
                }
                fit={ImgixFit.cover}
                lazyload={lazyload}
              />
            }
          />
        </Link>
      )}
      imageRatio={imageRatio}
      productCardVariant={productCardVariant}
    />
  )
}

export default ProductCard
