//jsxhook

import { Standfirst } from "@sixty-six-north/ui-system"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicItem, PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import React from "react"

export const StandfirstSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const credits = data.items.map((item: PrismicItem) => {
    const adapter = new PrismicItemAdapter(item)
    return {
      label: adapter.creditLabel(""),
      value: adapter.creditValue("")
    }
  })

  const adapter = new PrismicPrimaryAdapter(data.primary)

  return (
    <PrismicElementWrapper prismicStyle={data.primary} ignoreColorMode={true}>
      <Standfirst sentence={adapter.sentence("")} credits={credits} />
    </PrismicElementWrapper>
  )
}
