//jsxhook

import { processColorMode } from "@sixty-six-north/ui-system"
import { Box } from "theme-ui"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import React from "react"
export const HorizontalRuleSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box
        as="hr"
        sx={{
          m: 0,
          p: 0,
          width: data.primary.layout === "center" ? 48 : "100%",
          height: "1px",

          ...processColorMode(data.primary.color_mode),
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderRightWidth: "0px"
        }}
      />
    </PrismicElementWrapper>
  )
}
