//jsxhook

import {
  ArtDirectedImage,
  Colors,
  StandardXAxisPadding,
  ImgIXRatio,
  ParagraphSizeEnum,
  TechnicalParagraph
} from "@sixty-six-north/ui-system"
import { RichText } from "prismic-reactjs"
import React from "react"
import { Box } from "theme-ui"
import { LayoutRatio } from "../../utils/LayoutRatio"
import { htmlSerializer } from "../htmlSerializer"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { usePrismicLinks } from "../PrismicLinkedDocumentContext"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { useLoadVideoComponent } from "./VideoHooks"
import { PrismicImgixImage } from "./PrismicImgixImage"

export const MediaSlice: React.FC<{
  data: PrismicSlice
  imageAlt?: string
  onMediaClick?: () => void
}> = ({ data, imageAlt = "", onMediaClick }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const hlsVideoUrl = adapter.hlsVideoURL()
  const { ref, shouldLoadVideoComponent } = useLoadVideoComponent(!!hlsVideoUrl)
  const links = usePrismicLinks()
  const the_image = data.primary.the_image
    ? {
        ...data.primary.the_image,
        alt: data.primary.the_image.alt || imageAlt
      }
    : null

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          overflow: "hidden"
        }}
      >
        <Box
          ref={ref}
          onClick={() => onMediaClick && onMediaClick()}
          tabIndex={onMediaClick ? 0 : undefined}
          onKeyPress={k =>
            k.key === "Enter" ? onMediaClick && onMediaClick() : null
          }
          sx={{
            variant: `images.${LayoutRatio(adapter.ratio("16:9"))}`,
            overflow: "hidden",
            video: {
              width: "100%",
              height: "auto"
            }
          }}
        >
          {shouldLoadVideoComponent && (the_image || hlsVideoUrl) && (
            <ArtDirectedImage
              sx={{
                cursor: !!onMediaClick ? "pointer" : "default"
              }}
              noRatio={true}
              videoURL={adapter.videoURL("")}
              hlsVideoURL={hlsVideoUrl}
              autoPlay={true}
              loop={true}
              muted={true}
              imageNode={
                the_image ? (
                  <PrismicImgixImage
                    image={the_image}
                    aspectRatio={ImgIXRatio(adapter.ratio("16:9"))}
                    sizes={"100vw"}
                    width={the_image.dimensions && the_image.dimensions.width}
                    height={the_image.dimensions && the_image.dimensions.height}
                  />
                ) : null
              }
              imageAlt={the_image?.alt || ""}
            />
          )}
        </Box>
        {adapter.caption("").length > 0 && (
          <Box
            sx={{
              px: data.primary.width === "full" ? StandardXAxisPadding : 0
            }}
          >
            <TechnicalParagraph
              variant={ParagraphSizeEnum.small}
              sx={{
                mt: 24,
                color: Colors.grey[1],
                maxWidth: "48ch"
              }}
            >
              {
                <RichText
                  render={data.primary.caption}
                  htmlSerializer={htmlSerializer(links, false)}
                />
              }
            </TechnicalParagraph>
          </Box>
        )}
      </Box>
    </PrismicElementWrapper>
  )
}
