//jsxhook

import {
  H4,
  ImgIXRatio,
  PromotionalCard,
  TextVariants
} from "@sixty-six-north/ui-system"
import { useStoreContext } from "i18n/StoreHooks"
import dynamic from "next/dynamic"
import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Flex, Grid } from "theme-ui"
import { LayoutRatio } from "../../utils/LayoutRatio"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { PrismicImgixImage } from "./PrismicImgixImage"
import { PrismicDocumentLink } from "./PrismicLink"

const SizeGuide = dynamic(
  import("../../product/product/SizeGuide").then(it => it.SizeGuide)
)

export const MarketingSocialPromotions: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const primaryAdapter = new PrismicPrimaryAdapter(data.primary)
  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Flex
        sx={{
          flexDirection: ["column", null, null, "row"],
          flex: 1,
          width: "100%"
        }}
      >
        <Flex
          sx={{
            maxWidth: "32ch",
            pr: [16, 16, 24, 32, 48],
            mb: 32
          }}
        >
          <p sx={{ variant: TextVariants.technicalParagraphLarge }}>
            {primaryAdapter.sentence("")}
          </p>
        </Flex>
        <Box
          sx={{
            width: "100%",
            flex: 1,
            overflow: ["scroll", "scroll", "scroll", "hidden"]
          }}
        >
          <Grid
            sx={{
              width: "100%",
              "> *": {
                width: ["70vw", "40vw", "40vw", "auto"]
              }
            }}
            gap={[16, 16, 24, 32, 48]}
            columns={2}
          >
            {data.items.map((item, idx) => {
              const adapter = new PrismicItemAdapter(item)

              return (
                <PromotionalCard
                  key={idx}
                  section={adapter.sectionTitle("")}
                  title={adapter.cardTitle("")}
                  imageNode={
                    <PrismicImgixImage
                      image={item.image}
                      aspectRatio={ImgIXRatio(primaryAdapter.cardRatio("3:4"))}
                      sizes={"25vw"}
                    />
                  }
                  imageAlt={adapter.cardTitle("")}
                  hasGradientOverlay={item.hasgradientoverlay}
                  ratio={LayoutRatio(primaryAdapter.cardRatio("3:4"))}
                  mode={data.primary.color_mode}
                >
                  {item.link_1 && item.link_1.id && (
                    <PrismicDocumentLink
                      documentId={item.link_1.id}
                      sx={{ variant: "links.card" }}
                    >
                      {adapter.link1Label("")}
                    </PrismicDocumentLink>
                  )}

                  {item.link_2 && item.link_2.id && (
                    <PrismicDocumentLink
                      documentId={item.link_2.id}
                      sx={{ variant: "links.card" }}
                    >
                      {adapter.link2Label("")}
                    </PrismicDocumentLink>
                  )}

                  {item.link_3 && item.link_3.id && (
                    <PrismicDocumentLink
                      documentId={item.link_3.id}
                      sx={{ variant: "links.card" }}
                    >
                      {adapter.link3Label("")}
                    </PrismicDocumentLink>
                  )}

                  {item.link_4 && item.link_4.id && (
                    <PrismicDocumentLink
                      documentId={item.link_4.id}
                      sx={{ variant: "links.card" }}
                    >
                      {adapter.link4Label("")}
                    </PrismicDocumentLink>
                  )}

                  {item.link_1 && item.link_1.url && (
                    <a href={item.link_1.url} sx={{ variant: "links.card" }}>
                      {adapter.link1Label("")}
                    </a>
                  )}

                  {item.link_2 && item.link_2.url && (
                    <a href={item.link_2.url} sx={{ variant: "links.card" }}>
                      {adapter.link2Label("")}
                    </a>
                  )}

                  {item.link_3 && item.link_3.url && (
                    <a href={item.link_3.url} sx={{ variant: "links.card" }}>
                      {adapter.link3Label("")}
                    </a>
                  )}

                  {item.link_4 && item.link_4.url && (
                    <a href={item.link_4.url} sx={{ variant: "links.card" }}>
                      {adapter.link4Label("")}
                    </a>
                  )}
                </PromotionalCard>
              )
            })}
          </Grid>
        </Box>
      </Flex>
    </PrismicElementWrapper>
  )
}

export const CustomComponent: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const { t } = useTranslation()
  const { isShoppingInIceland } = useStoreContext()
  const WhatComponent = (component = data.primary.component) => {
    switch (component) {
      case "sizeguide":
        return (
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              maxWidth: ["100%", null, null, "75%", "66%"]
            }}
          >
            <H4 sx={{ mb: 16 }} className="color-mode-title">
              {t("checkout:mens")}
            </H4>
            <SizeGuide sizesFor="men" />

            <H4 sx={{ mt: 48, mb: 16 }} className="color-mode-title">
              {t("checkout:womens")}
            </H4>
            <SizeGuide sizesFor="women" />

            {isShoppingInIceland() && (
              <React.Fragment>
                <H4 sx={{ mt: 48, mb: 16 }} className="color-mode-title">
                  {t("checkout:kids")}
                </H4>
                <SizeGuide sizesFor="kids" />
              </React.Fragment>
            )}
          </Box>
        )
      default:
        return <Box />
    }
  }

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      {WhatComponent()}
    </PrismicElementWrapper>
  )
}
