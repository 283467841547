import { ColorRadio, FontWeight, Label2 } from "@sixty-six-north/ui-system"
import { FC, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Box, Container, Flex } from "theme-ui"

export interface SelectColor {
  colorName: string
  colorHex: string
  onSelect?: () => void
}

export interface ColorSelectorProps {
  selected: SelectColor
  colors: SelectColor[]
  onColorSelected?: () => void
}

export const ColorSelectorNew: FC<ColorSelectorProps> = ({
  selected,
  colors,
  onColorSelected = () => {}
}) => {
  const { t } = useTranslation("category")
  const vars = useRef({
    clickedColor: null
  })
  const { current: v } = vars

  const onSelectColor = color => {
    if (color.onSelect) {
      color.onSelect()
    }
    v.clickedColor = color.colorHex
  }

  useEffect(() => {
    if (!v.clickedColor) return
    //onColorSelected has to happen after color has been selected
    onColorSelected()
  }, [v.clickedColor])

  return (
    <Container>
      <Flex sx={{ mb: 16 }}>
        <Box sx={{ mr: 4 }}>
          <Label2
            id="color-label"
            sx={{ color: "text", fontWeight: FontWeight.textBold }}
          >
            {t("category:color", "Color")}
          </Label2>
        </Box>
        <Box>
          <Label2>{selected.colorName}</Label2>
        </Box>
      </Flex>
      <Flex role="radiogroup" aria-labelledby="color-label">
        {colors.map((color, index) => {
          const checked = color.colorHex === selected.colorHex
          return (
            <ColorRadio
              key={`color-${index}`}
              radioButtonName="color-selector-radio"
              colorName={color.colorName}
              colorHex={color.colorHex}
              onSelect={() => onSelectColor(color)}
              checked={checked}
              swatchSize={24}
              style={{ mr: [4, null, null, 16] }}
            />
          )
        })}
      </Flex>
    </Container>
  )
}
