//jsxhook

import { LineItem, Order } from "@commercetools/platform-sdk"
import { useCustomerState } from "customer/CustomerHooks"
import { useStoreContext } from "i18n/StoreHooks"
import { createLogger } from "utils/createLogger"
import { CartCalculator } from "../cart/CartCalculator"
import { AppConfig } from "../Config"
import { skuComponents, SKU, variantData } from "../product/VariantProxy"

const logger = createLogger("truefit")

const noOpTfcAPI = (...args: unknown[]) => {
  logger.info(`tfcapi is not defined when trying to: ${args}`)
}

export const useTrueFit = () => {
  const customer = useCustomerState()
  const { language, country } = useStoreContext()

  logger.info(`Truefit enabled stores: ${AppConfig.truefit.stores}`)
  const locale = `${language}_${country.toUpperCase()}`
  const available = AppConfig.truefit.stores.includes(country.toUpperCase())

  const truefitApi =
    // @ts-expect-error Need to check whether tfcapi has been loaded globally
    available && typeof tfcapi !== "undefined" ? tfcapi : noOpTfcAPI

  function productsFor(lineItems: LineItem[]) {
    const products = lineItems.map(item => {
      const { size, color } = variantData(item.variant, language)
      const price = CartCalculator.lineItemTotalPrice(item)
      const sku = item.variant.sku as SKU
      return {
        productId: skuComponents(sku)?.product,
        price: price.centAmount / 100,
        currency: price.currencyCode,
        size,
        sku,
        quantity: item.quantity,
        colorId: color
      }
    })

    return Promise.all(products)
  }

  const reportPurchase = (order: Order) =>
    productsFor(order.lineItems)
      .then(products => {
        return {
          userId: customer?.id || "",
          orderId: order.id,
          locale,
          products
        }
      })
      .then(event => {
        logger.log("truefitPurchase", event)
        truefitApi("track", "checkout", event)
        return true
      })

  return {
    available,
    calculate: () => truefitApi("calculate"),
    reportPurchase
  }
}
