//jsxhook

import {
  ArticleList,
  ArticleListItem,
  ImgIXRatio
} from "@sixty-six-north/ui-system"
import { countryToPath } from "i18n/Country"
import { DEFAULT_REGION } from "i18n/Region"
import { useStoreContext } from "i18n/StoreHooks"
import Link from "next/link"
import { getDefaultLocaleForCountry } from "prismic/PrismicLocale"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import { webNextPrismicApiUrl } from "../../Config"
import { useRouter } from "../../routing/useRouter"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicDocument, PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { PrismicImgixImage } from "./PrismicImgixImage"

const fetchArticlesByTags = async (tags: string[], locale: string) =>
  await fetch(webNextPrismicApiUrl("fetchDocumentsByTags"), {
    method: "POST",
    body: JSON.stringify({ tags, locale }),
    headers: {
      "Content-Type": "application/json"
    }
  })

export const RelatedArticleListSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const primaryAdapter = new PrismicPrimaryAdapter(data.primary)
  const [matchedArticles, setMatchedArticles] = useState([])
  const { t } = useTranslation("layout")
  const tags = data.items.map(i => i.tags || "").filter(i => i) || []
  const router = useRouter()
  const store = useStoreContext()
  const pathCountry = countryToPath(store.country || DEFAULT_REGION.country)
  const prismicLocale = getDefaultLocaleForCountry(store.country)

  useEffect(() => {
    async function fetchArticlesData() {
      try {
        const response = await fetchArticlesByTags(tags, prismicLocale)
        const res = (await response.json()) || []
        setMatchedArticles(res)
      } catch (e) {}
    }
    fetchArticlesData()
  }, [])

  if (!tags.length || !matchedArticles.length) return null

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <ArticleList
        title={primaryAdapter.article_list_title("")}
        countLabel={primaryAdapter.count_label("")}
      >
        {matchedArticles.map((document: PrismicDocument, idx) => {
          const { data: documentData } = document
          if (!documentData) return null
          const {
            meta_title = [],
            meta_list_description = [],
            thumbnail,
            article_thumbnail,
            link_label
          } = documentData

          const imageNode = !!article_thumbnail?.url
            ? article_thumbnail
            : thumbnail

          const title = (meta_title[0] && meta_title[0].text) || ""
          const description =
            (meta_list_description[0] && meta_list_description[0].text) || ""
          let down: number
          let up: number

          return (
            <Flex
              className={"article-list-item"}
              key={`related-article-${idx}`}
              onMouseDown={() => (down = +new Date())}
              onMouseUp={ev => {
                ev.preventDefault()
                up = +new Date()
                if (up - down < 200) {
                  if (documentData?.path) {
                    router.push(documentData.path)
                  }
                }
              }}
            >
              <ArticleListItem
                title={title}
                imageAlt={title}
                text={description}
                tags={document.tags.map(tag => ({ label: tag }))}
                imageNode={
                  imageNode && Object.keys(imageNode).length > 0 ? (
                    <PrismicImgixImage
                      image={imageNode}
                      sizes={"10vw"}
                      aspectRatio={ImgIXRatio(primaryAdapter.cardRatio("4:3"))}
                    />
                  ) : null
                }
                link={
                  <Link href={`/${pathCountry}${documentData?.path}`}>
                    {link_label || t("viewMore")}
                  </Link>
                }
              />
            </Flex>
          )
        })}
      </ArticleList>
    </PrismicElementWrapper>
  )
}
