//jsxhook

import {
  Banner,
  Hero,
  ImgIXRatio,
  LinkVariants,
  StatementHero
} from "@sixty-six-north/ui-system"
import React from "react"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { Link } from "./Link"
import { PrismicImgixImage } from "./PrismicImgixImage"
import { CategoryLink, PrismicDocumentLink, ProductLink } from "./PrismicLink"
import { useLoadVideoComponent } from "./VideoHooks"

export const HeroStatementSlice: React.FC<{
  data: PrismicSlice
  index: number
}> = ({ data, index }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const hlsVideoUrl = adapter.hlsVideoURL()
  const { ref, shouldLoadVideoComponent } = useLoadVideoComponent(!!hlsVideoUrl)

  return (
    <PrismicElementWrapper ourRef={ref} prismicStyle={data.primary}>
      {shouldLoadVideoComponent && data.primary.image && (
        <StatementHero
          key={data.id}
          statement={adapter.statement("")}
          sentence={adapter.sentence("")}
          label={adapter.label("")}
          imageAlt={data.primary.image.alt || ""}
          hasGradientOverlay={data.primary.hasgradientoverlay}
          videoURL={adapter.videoURL("")}
          hlsVideoURL={hlsVideoUrl}
          mark_as_h1={data.primary.mark_as_h1}
          imageNode={
            data.primary.image?.url && (
              <PrismicImgixImage
                image={data.primary.image}
                aspectRatio={ImgIXRatio(adapter.ratio("16:9"))}
                sizes={"100vw"}
                mobileSizes={index === 0 ? "50vw" : "100vw"}
                lazyload={index !== 0}
              />
            )
          }
        >
          {data.items.map((item, idx) => {
            const itemAdapter = new PrismicItemAdapter(item)

            if (item.link && item.link.id) {
              return (
                <PrismicDocumentLink
                  key={idx}
                  documentId={item.link?.id}
                  sx={{ variant: "links.card" }}
                >
                  {itemAdapter.label("")}
                </PrismicDocumentLink>
              )
            } else if (item.category_link_1) {
              return item.product_id ? (
                <ProductLink
                  key={idx}
                  prismicCategory={item.category_link_1}
                  prismicProduct={item.product_id}
                  sx={{ variant: `links.${LinkVariants.card}` }}
                >
                  {itemAdapter.label("")}
                </ProductLink>
              ) : (
                <CategoryLink
                  key={idx}
                  prismicCategory={item.category_link_1}
                  sx={{ variant: `links.${LinkVariants.card}` }}
                >
                  {itemAdapter.label("")}
                </CategoryLink>
              )
            } else if (
              item.link &&
              item.link.link_type === "Web" &&
              item.link.url
            ) {
              return (
                <Link key={idx} href={item.link.url}>
                  {itemAdapter.label("")}
                </Link>
              )
            } else {
              return null
            }
          })}
        </StatementHero>
      )}
    </PrismicElementWrapper>
  )
}

export const HeroTitleSentenceSlice: React.FC<{
  data: PrismicSlice
  index: number
}> = ({ data, index }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const hlsVideoUrl = adapter.hlsVideoURL()
  const { ref, shouldLoadVideoComponent } = useLoadVideoComponent(!!hlsVideoUrl)

  return (
    <PrismicElementWrapper ourRef={ref} prismicStyle={data.primary}>
      {shouldLoadVideoComponent && data.primary.image && (
        <Hero
          key={data.id}
          subTitle={adapter.subtitle("")}
          title={adapter.title("")}
          sentence={adapter.sentence("")}
          label={adapter.label("")}
          imageAlt={data.primary.image.alt}
          hasGradientOverlay={data.primary.hasgradientoverlay}
          videoURL={adapter.videoURL("")}
          hlsVideoURL={hlsVideoUrl}
          mark_as_h1={data.primary.mark_as_h1}
          imageNode={
            data.primary.image?.url && (
              <PrismicImgixImage
                image={data.primary.image}
                aspectRatio={ImgIXRatio(adapter.ratio("16:9"))}
                sizes={"100vw"}
                mobileSizes={index === 0 ? "50vw" : "100vw"}
                width={data.primary.image.dimensions.width}
                height={data.primary.image.dimensions.height}
                lazyload={index !== 0}
              />
            )
          }
        >
          {data.items.map((item, idx) => {
            const itemAdapter = new PrismicItemAdapter(item)
            if (item.link && item.link.id) {
              return (
                <PrismicDocumentLink
                  key={idx}
                  documentId={item.link?.id}
                  sx={{ variant: "links.card" }}
                >
                  {itemAdapter.label("")}
                </PrismicDocumentLink>
              )
            } else if (item.category_link_1) {
              return item.product_id ? (
                <ProductLink
                  key={idx}
                  prismicCategory={item.category_link_1}
                  prismicProduct={item.product_id}
                  sx={{ variant: `links.${LinkVariants.card}` }}
                >
                  {itemAdapter.label("")}
                </ProductLink>
              ) : (
                <CategoryLink
                  key={idx}
                  prismicCategory={item.category_link_1}
                  sx={{ variant: `links.${LinkVariants.card}` }}
                >
                  {itemAdapter.label("")}
                </CategoryLink>
              )
            } else if (
              item.link &&
              item.link.link_type === "Web" &&
              item.link.url
            ) {
              return (
                <Link key={idx} href={item.link.url}>
                  {itemAdapter.label()}
                </Link>
              )
            } else {
              return null
            }
          })}
        </Hero>
      )}
    </PrismicElementWrapper>
  )
}

export const BannerSlice: React.FC<{ data: PrismicSlice; index: number }> = ({
  data,
  index
}) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const hlsVideoUrl = adapter.hlsVideoURL()
  const { ref, shouldLoadVideoComponent } = useLoadVideoComponent(!!hlsVideoUrl)
  return (
    <PrismicElementWrapper ourRef={ref} prismicStyle={data.primary}>
      {shouldLoadVideoComponent && data.primary.image && (
        <Banner
          key={data.id}
          title={adapter.banner_title("")}
          text={adapter.sentence("")}
          imageAlt={data.primary.image.alt}
          hasGradientOverlay={data.primary.hasgradientoverlay}
          mode={data.primary.color_mode}
          videoURL={adapter.videoURL("")}
          hlsVideoURL={hlsVideoUrl}
          mark_as_h1={data.primary.mark_as_h1}
          imageNode={
            data.primary.image?.url && (
              <PrismicImgixImage
                image={data.primary.image}
                aspectRatio={ImgIXRatio("21:9")}
                sizes={"100vw"}
                mobileSizes={index === 0 ? "50vw" : "100vw"}
                width={data.primary.image.dimensions.width}
                height={data.primary.image.dimensions.height}
                lazyload={index !== 0}
              />
            )
          }
        >
          {data.items.map((item, idx) => {
            const itemAdapter = new PrismicItemAdapter(item)
            if (item.link && item.link.id) {
              return (
                <PrismicDocumentLink
                  key={idx}
                  documentId={item.link?.id}
                  sx={{ variant: "links.card" }}
                >
                  {itemAdapter.label("")}
                </PrismicDocumentLink>
              )
            } else if (item.category_link_1) {
              return item.product_id ? (
                <ProductLink
                  key={idx}
                  prismicCategory={item.category_link_1}
                  prismicProduct={item.product_id}
                  sx={{ variant: `links.${LinkVariants.card}` }}
                >
                  {itemAdapter.label("")}
                </ProductLink>
              ) : (
                <CategoryLink
                  key={idx}
                  prismicCategory={item.category_link_1}
                  sx={{ variant: `links.${LinkVariants.card}` }}
                >
                  {itemAdapter.label("")}
                </CategoryLink>
              )
            } else if (
              item.link &&
              item.link.link_type === "Web" &&
              item.link.url
            ) {
              return (
                <Link key={idx} href={item.link.url}>
                  {itemAdapter.label()}
                </Link>
              )
            } else {
              return null
            }
          })}
        </Banner>
      )}
    </PrismicElementWrapper>
  )
}
