//jsxhook

import { LocalizedString } from "@commercetools/platform-sdk"
import React, { ReactElement } from "react"

function relLink(
  hrefLang: string,
  store?: string,
  slug?: string,
  domain: URL = new URL("https://www.66north.com"),
  rel: "canonical" | "alternate" = "alternate"
) {
  if (!slug) return null
  const path = [store, slug].join("/").replace(/^\/+|\/+$/g, "")
  return (
    <link
      rel={rel}
      hrefLang={hrefLang}
      href={`${domain}${path}`}
      key={hrefLang}
    />
  )
}

export const alternateAndCanonicalUrlRelLinks = (
  slug?: LocalizedString
): ReactElement[] => {
  const { en: english, is: icelandic } = slug || {}
  return [
    relLink("en", undefined, english),
    relLink("en-gb", "uk", english),
    relLink("en-us", "us", english),
    relLink("en-dk", "dk", english),
    relLink("en-es", "eu", english),
    relLink("en-fr", "eu", english),
    relLink("en-de", "eu", english),
    relLink("is", "is", icelandic)
  ].filter(it => it !== null)
}
