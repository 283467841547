import { Language } from "@sixty-six-north/i18n/lib/Language"
import { TAG_FILTER_CONFIG, useTagFilter } from "filters/TagFilterHooks"
import { DomainCategory } from "product/models/DomainCategory"
import { useTranslation } from "react-i18next"
import { useStoreName } from "./Store"
import { string } from "yup"

export const useSEOTitle = (
  currentCategory: DomainCategory | undefined,
  language: Language
) => {
  // Constructs a SEO title based on the current category and tag filter
  // based on best practices. Helps with SEO and to reduce duplicate pages.
  const { t } = useTranslation("category")
  const storeName = useStoreName()
  const tagFilter = useTagFilter()
  const specCategoryName =
    TAG_FILTER_CONFIG[tagFilter]?.name[language] || tagFilter
  let titleCategoryName
  const northText = t("66north")
  if (specCategoryName !== "") {
    // Special categories using tagfilter, for example new arrivals or outlet
    titleCategoryName = `${currentCategory?.name[language]} - ${specCategoryName}`
  } else if (currentCategory?.ancestors?.map(a => a.name[language])) {
    // Standard categories
    titleCategoryName = `${currentCategory?.ancestors?.map(a => a.name[language])?.join(" > ")} - ${currentCategory?.name[language]}`
  } else if (currentCategory?.name[language]) {
    // If for some reason the ancestor is not set that should be ancestor category like gender
    titleCategoryName = `${currentCategory?.name[language]}`
  } else {
    titleCategoryName = ""
  }

  const seoTitle = [titleCategoryName, "|", northText, storeName].join(" ")
  return seoTitle
}

export const useSEOTitleProduct = (
  currentCategory: DomainCategory | undefined,
  language: Language,
  name: string,
  code: string
) => {
  // Constructs a SEO title based on the current category and tag filter
  // based on best practices. Helps with SEO and to reduce duplicate pages.
  const { t } = useTranslation("category")
  const storeName = useStoreName()
  const northText = t("66north")

  const gender = `${currentCategory?.ancestors?.map(a => a.name[language])?.join(" > ")}`

  const seoTitle = [
    `${gender} - ${name} - ${code}`, // Name is not unique for products
    "|",
    northText,
    storeName
  ].join(" ")
  return seoTitle
}

export const useSEOTitlePrismic = (
  documentName: string,
  language: Language
) => {
  // Constructs a SEO title based on the current category and tag filter
  // based on best practices. Helps with SEO and to reduce duplicate pages.
  const { t } = useTranslation("category")
  const storeName = useStoreName()
  const northText = t("66north")

  const seoTitle = [`${documentName}`, "|", northText, storeName].join(" ")
  return seoTitle
}
