//jsxhook

import { ResizeObserver } from "@juggle/resize-observer"
import {
  CategoryCard,
  enterObjectTransitionProperties,
  ImgIXRatio,
  LinkVariants,
  PromotionalCard,
  SmallArrowLeft,
  SmallArrowRight
} from "@sixty-six-north/ui-system"
import { m } from "framer-motion"
import { RichText } from "prismic-reactjs"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useMeasure from "react-use-measure"
import { Box, Flex, Grid } from "theme-ui"
import { useRouter } from "../../routing/useRouter"
import { LayoutRatio } from "../../utils/LayoutRatio"
import { htmlSerializer } from "../htmlSerializer"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import {
  usePrismicLinkedDocuments,
  usePrismicLinks
} from "../PrismicLinkedDocumentContext"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { useLoadVideoComponent } from "./VideoHooks"
import { PrismicImgixImage } from "./PrismicImgixImage"
import {
  CategoryLink,
  PrismicDocumentLink,
  PrismicLink,
  ProductLink
} from "./PrismicLink"

const cardImageSize = columns => {
  switch (columns) {
    case 4:
      return "20vw"
    case 3:
      return "33vw"
    case 2:
      return "50vw"
    case 1:
      return "100vw"
    default:
      return "50vw"
  }
}

const promoCardImageSize = columns => {
  switch (columns) {
    case 4:
      return "20vw"
    case 3:
      return "33vw"
    case 2:
      return "50vw"
    case 1:
      return "100vw"
    default:
      return "50vw"
  }
}

export const CardSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  // we need to merge this interfaces in one
  const primaryAdapter = new PrismicPrimaryAdapter(data.primary)
  const links = usePrismicLinks()
  const { t } = useTranslation("layout")
  const linkedDocuments = usePrismicLinkedDocuments()
  const hasHlsVideo = !!data.items.find(it => it.imgix_video?.playback_url)
  const { ref: gridContainerRef, shouldLoadVideoComponent } =
    useLoadVideoComponent(hasHlsVideo)
  const halfPageGridSpacing = [0, 0, 16, 24, "half-56"]
  //const gridSpacing = [24, 24, 32, 48, 56] = if we need to check cards matching everything else
  const router = useRouter()
  const isGallery = data.primary.display_as_gallery || false
  const TotalColumns = parseInt(data.primary.columns, 10)

  const DesktopGallery = data.items.length > TotalColumns && isGallery

  const GridColumns = isGallery
    ? DesktopGallery
      ? data.items.length
      : TotalColumns
    : [1, null, TotalColumns]

  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })
  const overFlowElement = useRef<HTMLInputElement>(null)
  const [motionValue, setMotionValue] = useState(0)

  const extractCardInformationForItem = item => {
    const adapter = new PrismicItemAdapter(item)
    let cardTitle = adapter.cardTitle("")
    let cardText =
      item.card_text && item.card_text[0]?.text ? item.card_text : undefined
    let imageNode = item.image
    const cardDocument1 = linkedDocuments.find(d => d.id === item.link_1.id)
    const cardDocument2 = linkedDocuments.find(d => d.id === item.link_2.id)
    const cardDocument3 = linkedDocuments.find(d => d.id === item.link_3.id)
    const cardDocument4 = linkedDocuments.find(d => d.id === item.link_4.id)
    const mainCardDocument =
      cardDocument1 || cardDocument2 || cardDocument3 || cardDocument4

    const link1Label =
      adapter.link1Label("") || cardDocument1?.data?.link_label || t("viewMore")
    const link2Label =
      adapter.link2Label("") || cardDocument2?.data?.link_label || t("viewMore")
    const link3Label =
      adapter.link3Label("") || cardDocument3?.data?.link_label || t("viewMore")
    const link4Label =
      adapter.link4Label("") || cardDocument4?.data?.link_label || t("viewMore")

    if (mainCardDocument && mainCardDocument.data) {
      const { meta_title, meta_description, thumbnail } = mainCardDocument.data
      cardTitle = cardTitle || (meta_title && meta_title[0]?.text) || ""
      cardText = cardText || meta_description
      imageNode = imageNode || thumbnail
    }

    return {
      cardTitle,
      cardText,
      link1Label,
      link2Label,
      link3Label,
      link4Label,
      imageNode
    }
  }

  function scrollRight() {
    if (overFlowElement && overFlowElement.current) {
      let newX = motionValue + bounds.width * -1

      if (newX >= 0) newX = 0
      if (newX < (overFlowElement.current.scrollWidth - bounds.width) * -1)
        newX = (overFlowElement.current.scrollWidth - bounds.width) * -1

      setMotionValue(newX)
    }
  }

  function scrollLeft() {
    if (overFlowElement && overFlowElement.current) {
      let newX = motionValue - bounds.width * -1

      if (newX >= 0) newX = 0

      setMotionValue(newX)
    }
  }

  const cardOverFlow = isGallery
    ? ["scroll", "scroll", "scroll", DesktopGallery ? "visible" : "hidden"]
    : "visible"

  const cardWidths = isGallery
    ? ["80vw", "40vw", "38vw", DesktopGallery ? "25vw" : "auto"]
    : "100%"
  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Flex
        ref={ref}
        sx={{
          flex: 1,
          flexDirection: "column",
          overflow: cardOverFlow,
          "@media (hover: none) and (pointer: coarse)": {
            pt: 16,
            ".card-gallery-arrows": {
              display: "none"
            }
          }
        }}
      >
        {DesktopGallery && (
          <Flex
            className="card-gallery-arrows"
            sx={{
              alignSelf: "flex-end",
              height: 32,
              justifyContent: "flex-end",
              alignItems: "center",
              mb: 32,
              button: {
                cursor: "pointer",
                bg: data.primary.color_mode === "dark" ? "white.0" : "black.1",
                height: 32,
                width: 32,
                m: 0,
                p: 0,
                border: "none",
                path: {
                  fill:
                    data.primary.color_mode === "dark" ? "grey.0" : "white.0"
                },
                ":last-child": {
                  ml: 2
                },
                ":hover": {
                  bg: data.primary.color_mode === "dark" ? "black.0" : "blue.1",
                  path: {
                    fill:
                      data.primary.color_mode === "dark" ? "white.0" : "white.0"
                  }
                },
                ":focus": {
                  outline: "none",
                  bg: "blue.0",
                  path: {
                    fill:
                      data.primary.color_mode === "dark" ? "white.0" : "white.0"
                  }
                }
              }
            }}
          >
            <Box
              as="button"
              tabIndex={0}
              aria-label="Previous Slide"
              onClick={() => scrollLeft()}
            >
              <SmallArrowLeft aria-hidden="true" focusable={false} />
            </Box>
            <Box
              as="button"
              tabIndex={0}
              aria-label="Next Slide"
              onClick={() => scrollRight()}
            >
              <SmallArrowRight aria-hidden="true" focusable={false} />
            </Box>
          </Flex>
        )}

        <m.div
          animate={{ x: motionValue }}
          ref={gridContainerRef}
          transition={enterObjectTransitionProperties}
        >
          {shouldLoadVideoComponent && (
            <Grid
              gap={[16, 16, 24, 32, 48]}
              columns={GridColumns}
              ref={overFlowElement}
              sx={{
                ml:
                  data.primary.width === "half_right" ? halfPageGridSpacing : 0,
                rowGap: [48, 48, 24, 32, 48],
                width:
                  data.primary.width === "half_right" ||
                  data.primary.width === "half_left"
                    ? [
                        `100%`,
                        null,
                        null,
                        `${(6 / 8) * 100}%`,
                        `${(6 / 8) * 100}%`,
                        `${(4 / 8) * 100}%`
                      ]
                    : "100%",
                "& > div": {
                  width: cardWidths
                }
              }}
            >
              {data.items.map((item, idx) => {
                const adapter = new PrismicItemAdapter(item)
                const {
                  cardTitle,
                  cardText,
                  link1Label,
                  link2Label,
                  link3Label,
                  link4Label,
                  imageNode
                } = extractCardInformationForItem(item)
                let down: number
                let up: number

                // let category: any
                // let categoryLink: any
                // if (item.category_link_1) {
                //   category = useCategoryForSlug(item.category_link_1.slug)
                //   if (category) categoryLink = builder.categoryLink(category)
                // }

                return (
                  <Flex
                    sx={{
                      flexDirection: "column",
                      width: "100%",
                      display:
                        data.primary.width === "half_right" ||
                        data.primary.width === "half_left"
                          ? "block"
                          : "flex", // to ensure the card link doesnt fly to the bototm of the cell
                      flex:
                        data.primary.width === "half_right" ||
                        data.primary.width === "half_left"
                          ? undefined
                          : 1,
                      height: "auto",
                      cursor:
                        item.link_1 && (item.link_1.id || item.link_1.url)
                          ? "pointer"
                          : "default"
                    }}
                    key={idx}
                    onMouseDown={() => (down = +new Date())}
                    onMouseUp={(ev: React.MouseEvent) => {
                      ev.preventDefault()
                      up = +new Date()
                      if (up - down < 200) {
                        if (item.link_1) {
                          if (item.link_1.id) {
                            const link = links.linkForDocumentId(item.link_1.id)
                            if (link) {
                              router.push(link.href, link.as)
                            }
                          } else if (item.link_1.url) {
                            router.push(item.link_1.url)
                          }
                        }
                      }
                    }}
                  >
                    <CategoryCard
                      section={adapter.sectionTitle("")}
                      title={cardTitle}
                      message={
                        cardText && (
                          <RichText
                            render={cardText}
                            htmlSerializer={htmlSerializer(links, false)}
                          />
                        )
                      }
                      videoURL={item.video?.url}
                      hlsVideoURL={item.imgix_video?.playback_url}
                      imageNode={
                        imageNode &&
                        imageNode?.url && (
                          <PrismicImgixImage
                            image={imageNode}
                            aspectRatio={ImgIXRatio(
                              primaryAdapter.cardRatio("16:9")
                            )}
                            sizes={cardImageSize(
                              parseInt(data.primary.columns, 10)
                            )}
                          />
                        )
                      }
                      imageAlt={cardTitle}
                      hasGradientOverlay={item.hasgradientoverlay}
                      ratio={LayoutRatio(primaryAdapter.cardRatio("16:9"))}
                      mode={data.primary.color_mode}
                    >
                      {item.link_1 && item.link_1.id && (
                        <PrismicDocumentLink
                          documentId={item.link_1.id}
                          sx={{ variant: `links.${LinkVariants.card}` }}
                        >
                          {link1Label}
                        </PrismicDocumentLink>
                      )}

                      {item.link_2 && item.link_2.id && (
                        <PrismicDocumentLink
                          documentId={item.link_2.id}
                          sx={{ variant: `links.${LinkVariants.card}` }}
                        >
                          {link2Label}
                        </PrismicDocumentLink>
                      )}

                      {item.link_3 && item.link_3.id && (
                        <PrismicDocumentLink
                          documentId={item.link_3.id}
                          sx={{ variant: `links.${LinkVariants.card}` }}
                        >
                          {link3Label}
                        </PrismicDocumentLink>
                      )}

                      {item.link_4 && item.link_4.id && (
                        <PrismicDocumentLink
                          documentId={item.link_4.id}
                          sx={{ variant: `links.${LinkVariants.card}` }}
                        >
                          {link4Label}
                        </PrismicDocumentLink>
                      )}

                      {item.link_1 && item.link_1.url && (
                        <PrismicLink
                          href={item.link_1.url}
                          sx={{ variant: `links.${LinkVariants.card}` }}
                        >
                          {link1Label}
                        </PrismicLink>
                      )}

                      {item.link_2 && item.link_2.url && (
                        <PrismicLink
                          href={item.link_2.url}
                          sx={{ variant: `links.${LinkVariants.card}` }}
                        >
                          {link2Label}
                        </PrismicLink>
                      )}

                      {item.link_3 && item.link_3.url && (
                        <PrismicLink
                          href={item.link_3.url}
                          sx={{ variant: `links.${LinkVariants.card}` }}
                        >
                          {link3Label}
                        </PrismicLink>
                      )}

                      {item.link_4 && item.link_4.url && (
                        <PrismicLink
                          href={item.link_4.url}
                          sx={{ variant: `links.${LinkVariants.card}` }}
                        >
                          {link4Label}
                        </PrismicLink>
                      )}

                      {item.category_link_1 && (
                        <>
                          {item.product_id ? (
                            <ProductLink
                              key={idx}
                              prismicCategory={item.category_link_1}
                              prismicProduct={item.product_id}
                              sx={{ variant: `links.${LinkVariants.card}` }}
                            >
                              {link1Label}
                            </ProductLink>
                          ) : (
                            <CategoryLink
                              prismicCategory={item.category_link_1}
                              sx={{ variant: `links.${LinkVariants.card}` }}
                            >
                              {link1Label}
                            </CategoryLink>
                          )}
                        </>
                      )}

                      {item.category_link_2 && (
                        <>
                          {item.product_id_2 ? (
                            <ProductLink
                              key={idx}
                              prismicCategory={item.category_link_2}
                              prismicProduct={item.product_id_2}
                              sx={{ variant: `links.${LinkVariants.card}` }}
                            >
                              {link2Label}
                            </ProductLink>
                          ) : (
                            <CategoryLink
                              prismicCategory={item.category_link_2}
                              sx={{ variant: `links.${LinkVariants.card}` }}
                            >
                              {link2Label}
                            </CategoryLink>
                          )}
                        </>
                      )}

                      {item.category_link_3 && (
                        <>
                          {item.product_id_3 ? (
                            <ProductLink
                              key={idx}
                              prismicCategory={item.category_link_3}
                              prismicProduct={item.product_id_3}
                              sx={{ variant: `links.${LinkVariants.card}` }}
                            >
                              {link3Label}
                            </ProductLink>
                          ) : (
                            <CategoryLink
                              prismicCategory={item.category_link_3}
                              sx={{ variant: `links.${LinkVariants.card}` }}
                            >
                              {link3Label}
                            </CategoryLink>
                          )}
                        </>
                      )}

                      {item.category_link_4 && (
                        <>
                          {item.product_id_4 ? (
                            <ProductLink
                              key={idx}
                              prismicCategory={item.category_link_4}
                              prismicProduct={item.product_id_4}
                              sx={{ variant: `links.${LinkVariants.card}` }}
                            >
                              {link4Label}
                            </ProductLink>
                          ) : (
                            <CategoryLink
                              prismicCategory={item.category_link_4}
                              sx={{ variant: `links.${LinkVariants.card}` }}
                            >
                              {link4Label}
                            </CategoryLink>
                          )}
                        </>
                      )}
                    </CategoryCard>
                  </Flex>
                )
              })}
            </Grid>
          )}
        </m.div>
      </Flex>
    </PrismicElementWrapper>
  )
}

export const PromotionsSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  // we need to merge this interfaces in one
  const primaryAdapter = new PrismicPrimaryAdapter(data.primary)
  const links = usePrismicLinks()
  //const gridSpacing = [24, 24, 32, 48, 56] = if we need to check cards matching everything else
  const router = useRouter()

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Grid
        gap={0}
        columns={[1, null, parseInt(data.primary.columns, 10)]}
        sx={{
          width: "100%"
        }}
      >
        {data.items.map((item, idx) => {
          const adapter = new PrismicItemAdapter(item)
          let down: number
          let up: number

          return (
            <div
              key={idx}
              onMouseDown={() => (down = +new Date())}
              onMouseUp={(ev: React.MouseEvent) => {
                ev.preventDefault()
                up = +new Date()
                if (up - down < 200) {
                  if (item.link_1 && item.link_1.id) {
                    const link = links.linkForDocumentId(item.link_1.id)
                    if (link) {
                      router.push(link.href, link.as)
                    }
                  }
                }
              }}
            >
              <PromotionalCard
                section={adapter.sectionTitle("")}
                title={adapter.cardTitle("")}
                imageNode={
                  <PrismicImgixImage
                    image={item.image}
                    aspectRatio={ImgIXRatio(primaryAdapter.cardRatio("16:9"))}
                    sizes={promoCardImageSize(
                      parseInt(data.primary.columns, 10)
                    )}
                  />
                }
                imageAlt={adapter.cardTitle("")}
                hasGradientOverlay={item.hasgradientoverlay}
                ratio={LayoutRatio(primaryAdapter.cardRatio("16:9"))}
                mode={data.primary.color_mode}
              >
                {item.link_1 && item.link_1.id && (
                  <PrismicDocumentLink
                    documentId={item.link_1.id}
                    sx={{ variant: `links.${LinkVariants.card}` }}
                  >
                    {adapter.link1Label("")}
                  </PrismicDocumentLink>
                )}

                {item.link_2 && item.link_2.id && (
                  <PrismicDocumentLink
                    documentId={item.link_2.id}
                    sx={{ variant: `links.${LinkVariants.card}` }}
                  >
                    {adapter.link2Label("")}
                  </PrismicDocumentLink>
                )}

                {item.link_3 && item.link_3.id && (
                  <PrismicDocumentLink
                    documentId={item.link_3.id}
                    sx={{ variant: `links.${LinkVariants.card}` }}
                  >
                    {adapter.link3Label("")}
                  </PrismicDocumentLink>
                )}

                {item.link_4 && item.link_4.id && (
                  <PrismicDocumentLink
                    documentId={item.link_4.id}
                    sx={{ variant: `links.${LinkVariants.card}` }}
                  >
                    {adapter.link4Label("")}
                  </PrismicDocumentLink>
                )}

                {item.link_1 && item.link_1.url && (
                  <PrismicLink
                    href={item.link_1.url}
                    sx={{ variant: `links.${LinkVariants.card}` }}
                  >
                    {adapter.link1Label("")}
                  </PrismicLink>
                )}

                {item.link_2 && item.link_2.url && (
                  <PrismicLink
                    href={item.link_2.url}
                    sx={{ variant: `links.${LinkVariants.card}` }}
                  >
                    {adapter.link2Label("")}
                  </PrismicLink>
                )}

                {item.link_3 && item.link_3.url && (
                  <PrismicLink
                    href={item.link_3.url}
                    sx={{ variant: `links.${LinkVariants.card}` }}
                  >
                    {adapter.link3Label("")}
                  </PrismicLink>
                )}

                {item.link_4 && item.link_4.url && (
                  <PrismicLink
                    href={item.link_4.url}
                    sx={{ variant: `links.${LinkVariants.card}` }}
                  >
                    {adapter.link4Label("")}
                  </PrismicLink>
                )}

                {item.category_link_1 && (
                  <>
                    {item.product_id ? (
                      <ProductLink
                        key={idx}
                        prismicCategory={item.category_link_1}
                        prismicProduct={item.product_id}
                        sx={{ variant: `links.${LinkVariants.card}` }}
                      >
                        {adapter.link1Label("")}
                      </ProductLink>
                    ) : (
                      <CategoryLink
                        prismicCategory={item.category_link_1}
                        sx={{ variant: `links.${LinkVariants.card}` }}
                      >
                        {adapter.link1Label("")}
                      </CategoryLink>
                    )}
                  </>
                )}

                {item.category_link_2 && (
                  <>
                    {item.product_id_2 ? (
                      <ProductLink
                        key={idx}
                        prismicCategory={item.category_link_2}
                        prismicProduct={item.product_id_2}
                        sx={{ variant: `links.${LinkVariants.card}` }}
                      >
                        {adapter.link2Label("")}
                      </ProductLink>
                    ) : (
                      <CategoryLink
                        prismicCategory={item.category_link_2}
                        sx={{ variant: `links.${LinkVariants.card}` }}
                      >
                        {adapter.link2Label("")}
                      </CategoryLink>
                    )}
                  </>
                )}

                {item.category_link_3 && (
                  <>
                    {item.product_id_3 ? (
                      <ProductLink
                        key={idx}
                        prismicCategory={item.category_link_3}
                        prismicProduct={item.product_id_3}
                        sx={{ variant: `links.${LinkVariants.card}` }}
                      >
                        {adapter.link3Label("")}
                      </ProductLink>
                    ) : (
                      <CategoryLink
                        prismicCategory={item.category_link_3}
                        sx={{ variant: `links.${LinkVariants.card}` }}
                      >
                        {adapter.link3Label("")}
                      </CategoryLink>
                    )}
                  </>
                )}

                {item.category_link_4 && (
                  <>
                    {item.product_id_4 ? (
                      <ProductLink
                        key={idx}
                        prismicCategory={item.category_link_4}
                        prismicProduct={item.product_id_4}
                        sx={{ variant: `links.${LinkVariants.card}` }}
                      >
                        {adapter.link4Label("")}
                      </ProductLink>
                    ) : (
                      <CategoryLink
                        prismicCategory={item.category_link_4}
                        sx={{ variant: `links.${LinkVariants.card}` }}
                      >
                        {adapter.link4Label("")}
                      </CategoryLink>
                    )}
                  </>
                )}
              </PromotionalCard>
            </div>
          )
        })}
      </Grid>
    </PrismicElementWrapper>
  )
}
