//jsxhook

import React, { useContext, useState, ReactNode } from "react"
import { GroupedProducts, groupProductsIntoShelves } from "./CategoryHooks"
import { FilterValue } from "../filters/FilterComponent"
import {
  FilteredProductsService,
  SubColors,
  useColors,
  useFilteredProducts,
  useFunctionalities,
  useSizes,
  useStyles
} from "../filters/FilterHooks"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { OrderBy } from "../product/ProductDalTypes"
import { ProductFacets } from "./ProductFacetFactory"

export interface ProductListingContextI {
  products: CoreProductInformation[]
  groupedProducts: GroupedProducts[]
  colors: FilterValue[]
  subColors: SubColors
  styles: FilterValue[]
  sizes: FilterValue[]
  functionalities: FilterValue[]
  ordering: OrderBy
  filter: FilteredProductsService
  imageView: ImageView
  setImageView: (view: ImageView) => void
  setOrdering: (orderBy: OrderBy) => void
}

export type ImageView = "PRODUCT" | "MODEL"

export const ProductListingContext =
  React.createContext<ProductListingContextI>(
    // @ts-ignore
    undefined
  )
export const ProductListingProvider: React.FC<{
  children?: ReactNode
  products: CoreProductInformation[]
  facets: ProductFacets
}> = ({ products, facets, children }) => {
  const { colors, subColors } = useColors(facets)
  const styles = useStyles(facets)
  const sizes = useSizes(facets)
  const functionalities = useFunctionalities(facets)
  const filter = useFilteredProducts(products)
  const groupedProducts = groupProductsIntoShelves(filter.filteredProducts)
  const [ordering, setOrdering] = useState<OrderBy>(OrderBy.priceDesc)
  const [imageView, setImageView] = useState<ImageView>("MODEL")

  return (
    <ProductListingContext.Provider
      value={{
        products,
        groupedProducts,
        colors,
        subColors,
        styles,
        sizes,
        functionalities,
        ordering,
        filter,
        imageView,
        setImageView,
        setOrdering
      }}
    >
      {children}
    </ProductListingContext.Provider>
  )
}

export const useProductListing = (): ProductListingContextI => {
  return useContext(ProductListingContext)
}
