import { useInView } from "react-intersection-observer"

export const useLoadVideoComponent = (containsVideoUrl: boolean) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    delay: 200
  })
  const shouldLoadVideoComponent = containsVideoUrl ? inView : true
  return { ref, shouldLoadVideoComponent }
}
