//jsxhook

import {
  Colors,
  H4,
  H6,
  Label2,
  rawColorValues,
  Statistic
} from "@sixty-six-north/ui-system"
import { RichText, RichTextBlock } from "prismic-reactjs"
import { Box, Grid } from "theme-ui"
import { htmlSerializer } from "../htmlSerializer"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { usePrismicLinks } from "../PrismicLinkedDocumentContext"
import { PrismicDesignTokenColors, PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import React from "react"

export const StatisticSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  //const primaryAdapter = new PrismicPrimaryAdapter(data.primary)
  const links = usePrismicLinks()
  //  const halfPageGridSpacing = [12, 12, 16, 24, "half-56"]

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Grid
        gap={[16, 16, 24, 32, 48]}
        columns={[1, null, 2, parseInt(data.primary.columns, 10)]}
        sx={{
          width: "100%"
        }}
      >
        {data.items.map((item, idx) => {
          const adapter = new PrismicItemAdapter(item)
          return (
            <Statistic
              key={idx}
              label={
                <RichText
                  render={item.label as RichTextBlock[]}
                  htmlSerializer={htmlSerializer(links, false)}
                />
              }
              stat={adapter.stat("")}
              mode={data.primary.color_mode}
            />
          )
        })}
      </Grid>
    </PrismicElementWrapper>
  )
}

export const StatisticGridSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const primaryAdapter = new PrismicPrimaryAdapter(data.primary)
  //  const halfPageGridSpacing = [12, 12, 16, 24, "half-56"]

  const backgroundColor = (color: PrismicDesignTokenColors) => {
    switch (color) {
      case "grey.0":
        return rawColorValues[Colors.grey][0]
      case "green.0":
        return rawColorValues[Colors.green][0]
      case "yellow.0":
        return rawColorValues[Colors.yellow][0]
      case "red.0":
        return rawColorValues[Colors.red][0]
      case "blue.0":
        return rawColorValues[Colors.blue][0]
      default:
        return rawColorValues[Colors.grey][0]
    }
  }

  const typeColor = (color: PrismicDesignTokenColors) => {
    switch (color) {
      case "grey.0":
        return rawColorValues[Colors.white][0]
      case "green.0":
        return rawColorValues[Colors.white][0]
      case "yellow.0":
        return rawColorValues[Colors.grey][0]
      case "red.0":
        return rawColorValues[Colors.white][0]
      case "blue.0":
        return rawColorValues[Colors.white][0]
      default:
        return rawColorValues[Colors.white][0]
    }
  }

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box>
        <H4 sx={{ color: "grey.0" }}>{primaryAdapter.statistic_title("")}</H4>
        <H6 sx={{ mt: 8 }}>{primaryAdapter.subtitle("")}</H6>
      </Box>

      <Grid
        gap={16}
        columns={[2, null, parseInt(data.primary.columns, 10)]}
        sx={{
          width: "100%",
          mt: 24
        }}
      >
        {data.items.map((item, idx) => {
          const adapter = new PrismicItemAdapter(item)
          return (
            <Box
              key={`stat-grid-item-${idx}`}
              sx={{
                bg: item.background_color
                  ? backgroundColor(item.background_color)
                  : "grey.0",
                color: item.background_color
                  ? typeColor(item.background_color)
                  : "white.0",
                p: 24
              }}
            >
              <H6>{adapter.value("")}</H6>
              <Label2 sx={{ mt: 4 }}>{adapter.label("")}</Label2>
            </Box>
          )
        })}
      </Grid>
    </PrismicElementWrapper>
  )
}
