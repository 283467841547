//jsxhook

import { SectionTitle } from "@sixty-six-north/ui-system"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import React from "react"

export const SectionTitleSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <SectionTitle
        title={adapter.section_title("")}
        subtitle={adapter.subtitle("")}
        mode={data.primary.color_mode}
      />
    </PrismicElementWrapper>
  )
}
