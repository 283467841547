//jsxhook

import { ImgIXRatio, Window, WindowVariants } from "@sixty-six-north/ui-system"
import { RichText } from "prismic-reactjs"
import React from "react"
import { htmlSerializer } from "../htmlSerializer"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { usePrismicLinks } from "../PrismicLinkedDocumentContext"
import {
  PrismicImage,
  PrismicItem,
  PrismicPrimaryProps,
  PrismicSlice
} from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { useLoadVideoComponent } from "./VideoHooks"

export const WindowSlice: React.FC<{
  data: PrismicSlice
  imageAlt?: string
  onImageClick?: (idx: number) => void
}> = ({ data, imageAlt = "", onImageClick }) => {
  const items = data.items
    .filter(it => !!it.image || !!it.imgix_video)
    .map((item: PrismicItem) => ({
      ...item.image,
      alt: item.image?.alt || imageAlt,
      imgix_video: item.imgix_video?.playback_url
    }))

  function useWindowVariant(data: PrismicPrimaryProps): WindowVariants {
    // Return type: string
    return data?.window_variant || "tower_block_left"
  }
  const links = usePrismicLinks()
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const hasVideoUrl = !!items.find(i => !!i.imgix_video)
  const windowVariant = useWindowVariant(data.primary)
  const { ref, shouldLoadVideoComponent } = useLoadVideoComponent(hasVideoUrl)

  return (
    <PrismicElementWrapper ourRef={ref} prismicStyle={data.primary}>
      {shouldLoadVideoComponent && (
        <Window
          items={items as PrismicImage[]}
          onImageClick={onImageClick}
          window_variant={windowVariant}
          ratio={ImgIXRatio(adapter.ratio("none"))}
          caption={
            adapter.caption("").length > 0 ? (
              <RichText
                render={data.primary.caption}
                htmlSerializer={htmlSerializer(links, false)}
              />
            ) : null
          }
        />
      )}
    </PrismicElementWrapper>
  )
}
