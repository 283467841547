import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import logger from "../utils/logger"

export const useRecaptcha = () => {
  const uninitialisedRecaptcha = _ => {
    logger.info("Recaptcha failed to initialise")
    return Promise.reject("recaptcha failed to initialise")
  }

  const { executeRecaptcha = uninitialisedRecaptcha } = useGoogleReCaptcha()

  return (action: string): Promise<string | undefined> => {
    return executeRecaptcha(action).then(
      (recaptchaToken: string | undefined) => recaptchaToken,
      error => {
        logger.error(error)
        return "recaptchaToken"
      }
    )
  }
}
