//jsxhook
import { ResizeObserver } from "@juggle/resize-observer"
import {
  enterObjectTransitionProperties,
  FontWeight,
  H2,
  H6,
  Label2,
  Label3,
  ScrollableProductGrid,
  ScrollableProductGridNew,
  SmallArrowDown,
  SmallArrowUp
} from "@sixty-six-north/ui-system"
import { AnimatePresence, m } from "framer-motion"
import { CarouselStyle, CarouselPaginationType } from "prismic/PrismicModels"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useMeasure from "react-use-measure"
import { Box, Flex, Grid } from "theme-ui"
import { ContinuousCarousel } from "../components/ContinuousCarousel"

export interface Look {
  title?: string
  products: React.FC[]
}

export interface Collection {
  title?: string
  looks: Look[]
}

export const border = {
  border: "1px solid",
  borderColor: "grey.3"
}

export const Looks: React.FC<{
  looks: { title?: string; select: () => void }[]
  currentLook?: string
  variant?: CarouselStyle
}> = ({ looks, currentLook, variant = "original" }) => {
  const looksWithTitle = looks.filter(look => !!look.title)
  const paddingX = variant === "original" ? 32 : 12

  return (
    <Box
      sx={{
        flex: 1,
        overflow: ["scroll", "scroll", "scroll", "visible"]
      }}
    >
      <Grid
        gap={0}
        columns={`repeat(${looksWithTitle.length},min-content)`}
        sx={{
          "& > *": {
            borderLeft: "none",
            borderRight: variant === "original" ? "1px solid" : "none",
            borderColor: border.borderColor,
            width: "auto",
            whiteSpace: "nowrap",
            paddingX
          },
          "& > *:first-of-type": {
            pl: variant === "original" ? "2rem" : "0"
          },
          "& > *:last-child": {
            borderRight: "none"
          }
        }}
      >
        {looksWithTitle.map(look => {
          return (
            <React.Fragment key={`look-tab-${look.title}`}>
              <Box
                as="button"
                sx={{
                  height: variant === "original" ? 72 : 32,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  background: "none",
                  borderBottom: "none",
                  borderTop: "none"
                }}
                onClick={look.select}
                onKeyDown={e => e.key === "Enter" && look.select()}
              >
                <Label3
                  sx={{
                    marginY: "auto",
                    textAlign: "center",
                    fontWeight: FontWeight.textBold,
                    width: "auto",
                    minWidth: "unset",
                    color: currentLook === look.title ? "grey.0" : "grey.1",
                    pb: variant === "pdp" ? 8 : 0,
                    borderBottom:
                      variant === "pdp"
                        ? currentLook === look.title
                          ? "1px solid black"
                          : "1px solid transparent"
                        : "none"
                  }}
                >
                  {look.title}
                </Label3>
              </Box>
            </React.Fragment>
          )
        })}
      </Grid>
    </Box>
  )
}

export const SingleCollectionSingleLook: React.FC<{
  collections: Collection[]
  variant?: CarouselStyle
  carouselConfiguration?: CarouselConfiguration
}> = ({ collections, variant, carouselConfiguration }) => {
  const products = collections.flatMap(collection =>
    collection.looks.flatMap(look => look.products)
  )
  const RecommendationsGrid =
    variant === "original"
      ? ScrollableProductGrid
      : variant === "carousel"
        ? ContinuousCarousel
        : ScrollableProductGridNew

  return (
    <Box
      sx={{
        marginTop: 32,
        marginBottom: 32
      }}
    >
      <RecommendationsGrid columns={products.length} {...carouselConfiguration}>
        {products.map((Product, pid) => (
          <Product key={`look-${pid}`} />
        ))}
      </RecommendationsGrid>
    </Box>
  )
}

export const SingleCollectionMultipleLooks: React.FC<{
  collections: Collection[]
  independentComponent: boolean
  variant?: CarouselStyle
  carouselConfiguration?: CarouselConfiguration
}> = ({
  collections,
  independentComponent,
  variant = "original",
  carouselConfiguration
}) => {
  const [currentLook, setCurrentLook] = useState(collections[0].looks[0])
  const marginTop = independentComponent ? 0 : 32

  useEffect(() => {
    setCurrentLook(collections[0].looks[0])
  }, [collections])

  const componentBorder = variant === "original" ? border : {}
  const RecommendationsGrid =
    variant === "original"
      ? ScrollableProductGrid
      : variant === "carousel"
        ? ContinuousCarousel
        : ScrollableProductGridNew

  return (
    <Flex sx={{ flexDirection: "column", mt: marginTop }}>
      <Flex
        sx={{
          alignItems: "center",
          ...componentBorder,
          borderRight: "none",
          borderLeft: "none",
          marginX: variant === "carousel" ? "3rem" : undefined
        }}
      >
        <Looks
          variant={variant}
          currentLook={currentLook.title}
          looks={collections
            .flatMap(it => it.looks)
            .map(it => ({
              title: it.title,
              select: () => setCurrentLook(it)
            }))}
        />
      </Flex>
      <Box
        sx={{
          marginTop: variant === "pdp" ? 16 : 32,
          marginBottom: variant === "pdp" ? 0 : 32
        }}
      >
        {currentLook.products.length > 0 && (
          <RecommendationsGrid
            carouselName={currentLook.title}
            columns={currentLook.products.length}
            {...carouselConfiguration}
          >
            {currentLook.products.map((Product, pid) => {
              return <Product key={`look-${pid}`} />
            })}
          </RecommendationsGrid>
        )}
      </Box>
    </Flex>
  )
}

export const MultipleCollectionsMultipleLooks: React.FC<{
  collections: Collection[]
  variant?: CarouselStyle
  carouselConfiguration?: CarouselConfiguration
}> = ({ collections, variant = "original", carouselConfiguration }) => {
  const [currentCollection, setCurrentCollection] = useState(collections[0])
  const [currentLook, setCurrentLook] = useState(currentCollection.looks[0])
  const [showDropdown, setShowDropdown] = useState(true)
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })
  const { t } = useTranslation("products")

  const RecommendationsGrid =
    variant === "original"
      ? ScrollableProductGrid
      : variant === "carousel"
        ? ContinuousCarousel
        : ScrollableProductGridNew

  useEffect(() => {
    setCurrentLook(currentCollection.looks[0])
    setShowDropdown(false)
  }, [currentCollection])

  useEffect(() => {
    setCurrentCollection(collections[0])
  }, [collections])

  return (
    <Flex
      sx={{
        flexDirection: "column",
        marginTop: 32,
        position: "relative"
      }}
    >
      <Flex
        sx={{
          flexDirection: ["column", null, null, "row"],
          borderTop: variant !== "carousel" && "1px solid",
          borderBottom: variant !== "carousel" && "1px solid",
          borderColor: border.borderColor,
          marginX: variant === "carousel" ? "3rem" : undefined,
          gap: variant === "carousel" ? "3rem" : undefined,
          alignItems: "center"
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            borderBottomStyle: variant !== "carousel" && [
              "solid",
              null,
              null,
              "none"
            ],
            paddingRight: variant === "carousel" ? "3rem" : undefined,
            borderBottomWidth: ["1px", null, null, 0],
            borderBottomColor: ["grey.3", null, null, "none"],
            borderRightStyle: ["none", null, null, "solid"],
            borderRightWidth: [null, null, null, "1px"],
            borderRightColor: "grey.3"
          }}
        >
          <Box
            as="a"
            tabIndex={0}
            sx={{
              display: "flex",
              height: 72,
              position: "relative",
              cursor: "pointer",
              px: variant !== "carousel" && 32,
              minWidth: 256
            }}
            onClick={() => setShowDropdown(it => !it)}
            onKeyDown={e => e.key === "Enter" && setShowDropdown(it => !it)}
          >
            <Box
              sx={{
                marginY: "auto"
              }}
            >
              {currentCollection.title && <H6>{currentCollection.title}</H6>}
              <Label2>
                {collections.length} {t("collections", "collections")}
              </Label2>
            </Box>
            <Box sx={{ marginY: "auto", ml: "auto", pl: 32 }}>
              {!showDropdown && <SmallArrowDown />}
              {showDropdown && <SmallArrowUp />}
            </Box>
          </Box>
          {showDropdown && (
            <Box
              sx={{
                position: "relative",
                width: "100%"
              }}
            >
              <AnimatePresence initial={false}>
                <m.div
                  key="collection-menu"
                  hidden={!showDropdown}
                  style={{
                    position: "absolute",
                    left: 0,
                    zIndex: 11
                  }}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: bounds.height + 24 }}
                  exit={{ opacity: 0, height: 0 }}
                  custom={enterObjectTransitionProperties}
                  sx={{
                    width: ["100%", null, null, "100%"]
                  }}
                >
                  <Flex
                    ref={ref}
                    sx={{
                      py: 24,
                      px: 32,
                      bg: "white.0",
                      boxShadow: "small",
                      position: "absolute",
                      width: "100%",
                      flexDirection: "column",
                      "& > * + div": {
                        mt: 16
                      },
                      "& a": {
                        color: "blue.0",
                        variant: "text.label2",
                        textDecoration: "none"
                      }
                    }}
                  >
                    {collections.map((collection, cid) => {
                      return (
                        <Flex key={cid}>
                          <Label2 sx={{ color: "blue.0" }}>
                            <Box
                              as="a"
                              tabIndex={0}
                              onClick={() => {
                                setCurrentCollection(collection)
                              }}
                              onKeyDown={e =>
                                e.key === "Enter" &&
                                setCurrentCollection(collection)
                              }
                              sx={{ marginRight: 4, cursor: "pointer" }}
                            >
                              {collection.title}
                            </Box>
                          </Label2>
                          <Label2 sx={{ color: "grey.1" }}>
                            ({collection.looks.length} {t("looks", "looks")})
                          </Label2>
                        </Flex>
                      )
                    })}
                  </Flex>
                </m.div>
              </AnimatePresence>
            </Box>
          )}
        </Flex>

        <Looks
          variant={variant}
          currentLook={currentLook.title}
          looks={currentCollection.looks.map(it => ({
            title: it.title,
            select: () => setCurrentLook(it)
          }))}
        />
      </Flex>

      <Box
        sx={{
          marginTop: 32,
          marginBottom: 32
        }}
      >
        <RecommendationsGrid
          carouselName={currentLook.title}
          columns={currentLook.products.length}
          {...carouselConfiguration}
        >
          {currentLook.products.map((Product, pid) => {
            return <Product key={`look-${pid}`} />
          })}
        </RecommendationsGrid>
      </Box>
    </Flex>
  )
}

interface CarouselConfiguration {
  paginationType?: CarouselPaginationType
  autoPaginateIntervalInSeconds?: number
  animationDurationInSeconds?: number
}

export const ProductRecommendations: React.FC<{
  subTitle?: string
  title?: string
  collections: Collection[]
  variant?: CarouselStyle
  carouselConfiguration?: CarouselConfiguration
}> = ({
  subTitle,
  title,
  collections,
  variant = "original",
  carouselConfiguration
}) => {
  const isSingleCollectionSingleLook =
    collections.length === 1 && collections[0].looks.length === 1

  const isSingleCollectionsMultipleLooks =
    collections.length === 1 && collections[0].looks.length > 1

  const isMultipleCollections = collections.length > 1

  const isIndependentComponent = title === undefined
  const style = isIndependentComponent
    ? { pt: 0, borderTop: "none" }
    : { pt: 32 }

  const componentBorder = variant === "original" ? border : {}
  const titleMargin = variant === "carousel" ? "3rem" : 32
  return (
    <Flex
      sx={{
        flexDirection: "column",
        width: "100%",
        ...componentBorder,
        ...style
      }}
    >
      {subTitle && (
        <Box
          sx={{
            marginX: titleMargin,
            color: "grey.1"
          }}
        >
          <H6>{subTitle}</H6>
        </Box>
      )}
      {title && (
        <Box
          sx={{
            marginX: titleMargin,
            color: "grey.0"
          }}
        >
          <H2>{title}</H2>
        </Box>
      )}
      <Box>
        {isSingleCollectionSingleLook && (
          <SingleCollectionSingleLook
            variant={variant}
            collections={collections}
            carouselConfiguration={carouselConfiguration}
          />
        )}
        {isSingleCollectionsMultipleLooks && (
          <SingleCollectionMultipleLooks
            variant={variant}
            collections={collections}
            independentComponent={isIndependentComponent}
            carouselConfiguration={carouselConfiguration}
          />
        )}
        {isMultipleCollections && (
          <MultipleCollectionsMultipleLooks
            variant={variant}
            collections={collections}
            carouselConfiguration={carouselConfiguration}
          />
        )}
      </Box>
    </Flex>
  )
}
