//jsxhook

import {
  H5,
  Label2,
  Label3,
  LinkVariants,
  Row,
  ScreenReaderOnly,
  SoundIcon,
  Tooltip
} from "@sixty-six-north/ui-system"
import { useStoreContext } from "i18n/StoreHooks"
import React from "react"
import { Box, Flex } from "theme-ui"
import { isIcelandicStore } from "../../FeatureFlags"
import { PrismicData } from "../../prismic/PrismicModels"

export const NameAndSku: React.FC<{
  name: string
  sku: string
  phoneticSpelling?: string
  nameSpellingTracking?: (mode?: string) => void
  LabelElement?: React.FC
  HeaderElement?: React.FC
  pronunciationData?: PrismicData | null
}> = ({
  name,
  phoneticSpelling,
  sku,
  nameSpellingTracking = () => {},
  HeaderElement = H5,
  LabelElement = Label3,
  pronunciationData,
  ...props
}) => {
  const store = useStoreContext()
  const phoneticSpellingStr = !isIcelandicStore(store) ? phoneticSpelling : ""

  const handlePlayAudio = url => {
    if (!url) return
    nameSpellingTracking("audio")
    new Audio(url).play()
  }

  return (
    <Row {...props}>
      <Flex
        sx={{
          gap: 4,
          flex: 1,
          flexWrap: "wrap",
          alignItems: "center"
        }}
      >
        {pronunciationData && (
          <Tooltip
            id="product-name-tooltip"
            title="How to pronounce"
            description={
              pronunciationData?.description
                ? pronunciationData?.description[0].text
                : ""
            }
            onTooltipShow={() => nameSpellingTracking()}
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            position="bottom"
            styled={false}
            customContent={
              <>
                <Label2 sx={{ color: "grey.1" }}>{phoneticSpellingStr}</Label2>
                {pronunciationData?.audio_file &&
                  pronunciationData?.audio_file.url && (
                    <>
                      {phoneticSpellingStr && " - "}
                      <button
                        onClick={() =>
                          handlePlayAudio(pronunciationData?.audio_file?.url)
                        }
                        sx={{
                          mt: 16,
                          variant: `links.${LinkVariants.standard}`,
                          cursor: "pointer",
                          padding: 0,
                          border: 0,
                          backgroundColor: "transparent"
                        }}
                      >
                        Play audio
                      </button>
                    </>
                  )}
              </>
            }
          >
            <HeaderElement
              as="h1"
              sx={{
                display: "inline-block",
                mr: 4
              }}
            >
              {name}
            </HeaderElement>
            {phoneticSpellingStr && (
              <Flex
                sx={{
                  alignItems: "flex-end",
                  mr: 16
                }}
              >
                <Label2 sx={{ color: "grey.1" }}>{phoneticSpellingStr}</Label2>
                <Row sx={{ ml: 4, display: "inline-flex" }}>
                  <SoundIcon sx={{ ml: 4 }} aria-hidden={true} />
                </Row>
              </Flex>
            )}
          </Tooltip>
        )}
        {!pronunciationData && <HeaderElement as="h1">{name}</HeaderElement>}
        {!pronunciationData && phoneticSpellingStr && (
          <Label2 sx={{ color: "grey.1", display: "block" }}>
            {phoneticSpellingStr}
          </Label2>
        )}
      </Flex>
      <Box
        sx={{
          ml: "auto",
          justifyContent: "flex-end",
          textAlign: "right"
        }}
      >
        <LabelElement>
          <ScreenReaderOnly>Product code: </ScreenReaderOnly>
          {sku}
        </LabelElement>
      </Box>
    </Row>
  )
}
