import React, { FC } from "react"
import { Row, SizeButton, SizeButtonNew } from "@sixty-six-north/ui-system"

export interface SelectSize {
  label: string
  onSelect?: () => void
  isInStock: boolean
}

export interface SizeProps {
  selected: SelectSize
  sizes: SelectSize[]
  backgroundColors?: Array<string | undefined>
  disabledOutofStockSizes?: boolean
  labelledBy?: string
}

export const SizeSelectorNew: FC<SizeProps> = ({
  sizes,
  selected,
  backgroundColors,
  labelledBy,
  disabledOutofStockSizes = true
}) => {
  const factor = 10
  const style = {
    justifyContent: "space-between",
    maxWidth: [
      `min(${(sizes.length + 1) * factor}%, 100%)`,
      `min(${sizes.length * factor}%, 60%)`,
      null,
      null,
      null
    ],
    "& label": {
      mr: 0,
      justifyContent: "flex-start",
      px: 8,
      "&:first-of-type": {
        pl: 0
      },
      "&:last-of-type": {
        pr: 0
      }
    }
  }

  return (
    <Row aria-labelledby={labelledBy} role="radiogroup" sx={style}>
      {sizes.map((size, index) => {
        return (
          <SizeButtonNew
            size={size}
            isSelected={size.label === selected.label}
            key={`size=${index}`}
            backgroundColors={backgroundColors}
            disableOutOfStock={disabledOutofStockSizes}
          />
        )
      })}
    </Row>
  )
}

export const SizeSelector: FC<SizeProps> = ({
  sizes,
  selected,
  backgroundColors,
  labelledBy,
  disabledOutofStockSizes = true
}) => {
  const style = {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "grey.2",
    borderRadius: "2px"
  }

  return (
    <Row aria-labelledby={labelledBy} role="radiogroup" sx={style}>
      {sizes.map((size, index) => {
        return (
          <SizeButton
            size={size}
            isSelected={size.label === selected.label}
            key={`size=${index}`}
            backgroundColors={backgroundColors}
            disableOutOfStock={disabledOutofStockSizes}
          />
        )
      })}
    </Row>
  )
}
