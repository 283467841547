//jsxhook

import {
  ArtDirectedImage,
  Colors,
  ImgIXRatio,
  Label2,
  pillBoxStyle,
  pillLabelStyle,
  zIndices
} from "@sixty-six-north/ui-system"
import { usePreferredLanguage } from "i18n/StoreHooks"
import React, { useState } from "react"
import { Box, Flex, ThemeUICSSObject } from "theme-ui"
import { useAllCategoriesLookup } from "../../category/CategoryHooks"
import ProductCard from "../../product/components/ProductCard"
import { CoreProductInformation } from "../../product/models/DetailedProductInformation"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { useRecommendations } from "../PrismicProductsContext"
import { ProjectionWithPreferredColor } from "../RecommendationFilter"
import { PrismicElementWrapper } from "./Layout"
import { PrismicImgixImage } from "./PrismicImgixImage"

const CurrentProductCard: React.FC<{
  productCode?: string
  productCategory?: string
  preferredColorCode?: string
  productBadge?: string
}> = ({ productCode, productCategory, preferredColorCode, productBadge }) => {
  const language = usePreferredLanguage()

  const recommendations = useRecommendations()

  const allCategoriesLookup = useAllCategoriesLookup()

  const products: ProjectionWithPreferredColor[] =
    recommendations.productsList(productCode)
  const product: CoreProductInformation = products[0] && products[0].product

  if (!product) return null

  const category = allCategoriesLookup.firstSubCategoryUnderTopLevel(
    product,
    productCategory || "men"
  )
  const currentCategorySlug = category?.slug?.[language] || ""

  return (
    <ProductCard
      border={false}
      product={products[0].product}
      color={preferredColorCode}
      badge={productBadge}
      categorySlug={currentCategorySlug}
    />
  )
}

export const ProductAndAssetSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Flex
        sx={{
          flex: [null, null, 1],
          width: ["100%", null, "auto"],
          bg: Colors.grey,
          "&:after": {
            content: '""',
            pb: "100%"
          }
        }}
      >
        {data.primary.the_image && (
          <ArtDirectedImage
            noRatio={true}
            videoURL={adapter.videoURL("")}
            autoPlay={true}
            loop={true}
            muted={true}
            imageNode={
              <PrismicImgixImage
                image={data.primary.the_image}
                aspectRatio={ImgIXRatio(adapter.ratio("1:1"))}
                sizes={"40vw"}
                width={
                  data.primary.the_image.dimensions &&
                  data.primary.the_image.dimensions.width
                }
                height={
                  data.primary.the_image.dimensions &&
                  data.primary.the_image.dimensions.height
                }
              />
            }
            imageAlt={data.primary.the_image.alt}
          />
        )}
      </Flex>
      <Flex
        sx={{
          flex: [null, null, 1],
          width: ["100%", null, "auto"],
          bg: Colors.grey,
          alignItems: "center",
          justifyContent: "center",
          py: 32,
          pt: 48,
          position: "relative",
          "&:after": {
            content: '""',
            pb: "100%"
          }
        }}
      >
        <Flex
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            pt: 16,
            px: 16,
            pb: 32,
            zIndex: zIndices.page
          }}
        >
          <Flex
            sx={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "grey.2",
              borderRadius: "2px"
            }}
          >
            {data.items.length > 1 &&
              data.items.map((tab, index) => {
                return (
                  <Box
                    key={`tab-${index}`}
                    sx={{
                      ...(pillBoxStyle(
                        currentTab === index
                      ) as ThemeUICSSObject)
                    }}
                  >
                    <button
                      sx={{
                        variant: `buttons.accessible`,

                        height: 28,
                        px: 12,
                        ":focus": {
                          outline: "none"
                        },
                        ...(pillLabelStyle(
                          currentTab === index
                        ) as ThemeUICSSObject),
                        width: "100%"
                      }}
                      onClick={() => setCurrentTab(index)}
                    >
                      <Label2 sx={{ position: "relative", top: "-2px" }}>
                        {tab.tab}
                      </Label2>
                    </button>
                  </Box>
                )
              })}
          </Flex>
        </Flex>
        <Box
          sx={{
            width: "80%"
          }}
        >
          {data.items[currentTab].product_id &&
            data.items[currentTab].product_id?.productCode && (
              <CurrentProductCard
                key={`product-asset-current-${currentTab}`}
                productCode={data.items[currentTab].product_id?.productCode}
                productCategory={data.items[currentTab].product_category}
                productBadge={data.items[currentTab].product_badge}
                preferredColorCode={data.items[currentTab].preferred_color_code}
              />
            )}
        </Box>
      </Flex>
    </PrismicElementWrapper>
  )
}
