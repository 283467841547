//jsxhook

import { Quote } from "@sixty-six-north/ui-system"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { usePreferredLanguage } from "i18n/StoreHooks"
import { linkResolver } from "prismic/PrismicLinks"
import { htmlSerializer } from "prismic/htmlSerializer"
import { usePrismicLinks } from "prismic/PrismicLinkedDocumentContext"
import { RichText } from "prismic-reactjs"
import React from "react"

export const QuoteSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  //const adapter = new PrismicPrimaryAdapter(data.primary)
  const language = usePreferredLanguage()
  const links = usePrismicLinks()
  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Quote
        useRule={data.primary.use_rule}
        typeSize={data.primary.quote_size}
        mode={data.primary.color_mode}
        lang={language === "is" ? "de" : "en"}
      >
        <RichText
          render={data.primary.the_text}
          linkResolver={linkResolver(links)}
          htmlSerializer={htmlSerializer(links, data.primary.mark_as_h1)}
        />
      </Quote>
    </PrismicElementWrapper>
  )
}
