//jsxhook

import {
  StandardXAxisPadding,
  processColorMode
} from "@sixty-six-north/ui-system"
import { Flex, ThemeUICSSObject } from "theme-ui"
import { PrismicPrimaryProps } from "../PrismicModels"
import React from "react"
export const PrismicElementWrapper: React.FC<{
  children: React.ReactNode
  ourRef?: (node?: Element | null) => void
  prismicStyle: PrismicPrimaryProps
  ignoreColorMode?: boolean
}> = ({
  children,
  ourRef,
  prismicStyle: {
    width = "standard",
    padding_top = "0",
    padding_bottom = "0",
    space_between = "none",
    mobile_visual_rule = false,
    aside = false,
    layout = "equal",
    header_distance = "with-navigation",
    color_mode = "light",
    color_background_override,
    color_text_override,
    alignment
  },
  ignoreColorMode = false
}) => {
  // turn props into styles
  // what width should we use
  const processedWidth = (w = width) => {
    switch (w) {
      case "full":
        return { width: "100vw", px: 0 }
      case "standard":
        return { width: "100vw", px: StandardXAxisPadding }
      case "half_left":
        return {
          width: ["100%", null, null, "50%"],
          pl: StandardXAxisPadding,
          pr: [24, 24, 48, 0]
        }
      case "half_right":
        return {
          width: ["100%", null, null, "50%"],
          pl: [24, 24, 48, 0],
          pr: StandardXAxisPadding
        }
      default:
        return { width: "100vw", px: StandardXAxisPadding }
    }
  }
  // should we add padding top
  const processPaddingTop = (pt = padding_top) => {
    switch (pt) {
      case "0":
        return { pt: 0 }
      case "24":
        return { pt: [16, 24] }
      case "32":
        return { pt: [32] }
      case "64":
        return { pt: [24, 24, 32, 48, 56] }
      case "128":
        return { pt: [48, 48, 64, 72, 128] }
      default:
        return { pt: 0 }
    }
  }

  // should we add padding bottom
  const processPaddingBottom = (pb = padding_bottom) => {
    switch (pb) {
      case "0":
        return { pb: 0 }
      case "24":
        return { pb: [16, 24] }
      case "32":
        return { pb: [32] }
      case "64":
        return { pb: [24, 24, 32, 48, 56] }
      case "128":
        return { pb: [48, 48, 64, 72, 128] }
      default:
        return { pb: 0 }
    }
  }
  // How should we space elements
  const processSpaceBetween = (sb = space_between) => {
    switch (sb) {
      case "none":
        return { mb: 0 }
      case "internal-small":
        return { mb: [24, 24, 24, 24, 28] }
      case "internal":
        return { mb: [24, 24, 32, 48, 56] }
      case "external":
        return { mb: [48, 48, 64, 72, 128] }
      default:
        return { mb: 0 }
    }
  }

  // How should we space elements
  const processMobileVisualRule = (mbr = mobile_visual_rule) => {
    switch (mbr) {
      case true:
        return {
          ":after": {
            mt: 24,
            position: "relative",
            top: 12,
            width: "10%",
            height: 1,
            bg: "grey.2",
            display: ["block", null, null, "none"],
            content: '" "'
          } as ThemeUICSSObject
        }
      case false:
        return undefined
    }
  }

  // How should we layout elements
  const processLayout = (ly = layout) => {
    switch (ly) {
      case "equal":
        return { justifyContent: "flex-start" }
      case "center":
        return {
          justifyContent: "center",
          alignItems: "center",
          "& > div": { width: "50%" }
        }
      default:
        return { justifyContent: "flex-start" }
    }
  }

  // Do we offset the margin for full bleed
  const processHeaderDistance = (hd = header_distance) => {
    switch (hd) {
      case "with-navigation":
        return { mt: 0 }
      case "none":
        return { mt: -88 }
      default:
        return { mt: 0 }
    }
  }

  return (
    <Flex
      ref={ourRef}
      as={aside ? "aside" : "div"}
      sx={{
        overflow: "hidden",
        flexDirection:
          width === "half_left" || width === "half_right"
            ? "column"
            : alignment === "right"
              ? "row-reverse"
              : "row",
        flexWrap: "wrap",
        ...processLayout(),
        ...processHeaderDistance(),
        ...processedWidth(),
        ...processPaddingTop(),
        ...processPaddingBottom(),
        ...processSpaceBetween(),
        ...processMobileVisualRule(),
        ...(!ignoreColorMode
          ? processColorMode(
              color_mode,
              color_background_override,
              color_text_override
            )
          : { bg: "transparent" })
      }}
    >
      {children}
    </Flex>
  )
}
