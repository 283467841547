import React, { ReactNode } from "react"
import { Link as AppLink } from "../../components/Link"

export const Link: React.FC<{
  children?: ReactNode
  href: string
}> = ({ href, children, ...props }) => {
  return (
    <AppLink as={href} href="/[region]/[...params]" passHref={true} {...props}>
      {children}
    </AppLink>
  )
}
