import fetch from "isomorphic-unfetch"
import { useRecaptcha } from "../../recaptcha/RecaptchaHooks"
import { useAxiosClient } from "../../AxiosClient"
import { SponsorshipFormData } from "./SponsorshipFormData"
import { AxiosResponse } from "axios"

interface ContactUsParams {
  name: string
  emailAddress: string
  phoneNumber: string
  message: string
  inquiryReason: string
  country: string
}

export const useContactUs = () => {
  const recaptcha = useRecaptcha()

  const contactUs = async ({
    name,
    emailAddress,
    phoneNumber,
    message,
    inquiryReason,
    country
  }: ContactUsParams): Promise<boolean> => {
    const recaptchaPromise = recaptcha("contactUs")
    return fetch("/api/contactUs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        meta: { recaptchaToken: await recaptchaPromise },
        name,
        emailAddress,
        phoneNumber,
        message,
        inquiryReason,
        country
      })
    }).then(it => it.status === 200)
  }

  return { contactUs }
}

interface SubscribeParams {
  firstName: string
  lastName: string
  emailAddress: string
  country: string
  signUpCheckbox: boolean
  agreeCheckbox: boolean
  segmentId?: string
  mandrillTemplate?: string
  tagName?: string
}

export const useSubscribe = () => {
  const recaptcha = useRecaptcha()

  const subscribe = async (
    {
      firstName,
      lastName,
      emailAddress,
      signUpCheckbox,
      agreeCheckbox,
      country,
      segmentId,
      tagName,
      mandrillTemplate
    }: SubscribeParams,
    listId?: string
  ): Promise<boolean> => {
    const recaptchaPromise = recaptcha("subscribe")
    return fetch("/api/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        meta: { recaptchaToken: await recaptchaPromise },
        firstName,
        lastName,
        emailAddress,
        country,
        listId,
        segmentId,
        mandrillTemplate,
        tagName,
        subscribe: signUpCheckbox
      })
    }).then(it => it.status === 200)
  }

  return { subscribe }
}

export const useSponsorship = () => {
  const recaptcha = useRecaptcha()
  const axios = useAxiosClient()

  const submit = async (
    data: SponsorshipFormData
  ): Promise<AxiosResponse<{ success: boolean }>> => {
    const recaptchaPromise = recaptcha("contactUs")
    return axios.post<{
      success: boolean
    }>("/api/sponsorship", {
      ...data,
      meta: {
        recaptchaToken: await recaptchaPromise
      }
    })
  }
  return {
    submit
  }
}
