//jsxhook

import { TableContent } from "@sixty-six-north/ui-system"
import { RichText } from "prismic-reactjs"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { usePrismicLinks } from "../PrismicLinkedDocumentContext"
import { PrismicItem, PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import React from "react"

import { htmlSerializer } from "../htmlSerializer"

export const TableSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const links = usePrismicLinks()
  const credits = data.items.map((item: PrismicItem) => {
    const adapter = new PrismicItemAdapter(item)
    return {
      label: adapter.label(""),
      value: (
        <RichText
          render={item.value}
          htmlSerializer={htmlSerializer(links, false)}
        />
      )
    }
  })

  const adapter = new PrismicPrimaryAdapter(data.primary)

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <TableContent
        title={adapter.table_title("")}
        values={credits}
        mode={data.primary.color_mode}
      />
    </PrismicElementWrapper>
  )
}
