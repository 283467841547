//jsxhook

import React, { useContext } from "react"
import { PrismicLinkedDocument } from "./PrismicModels"

export const PrismicRelatedContentBlocksContext = React.createContext<
  PrismicLinkedDocument[]
>([])

export const useRelatedContentBlocks = (): {
  relatedContentBlocks: PrismicLinkedDocument[]
} => {
  const relatedContentBlocks = useContext(PrismicRelatedContentBlocksContext)
  return {
    relatedContentBlocks
  }
}
