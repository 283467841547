//jsxhook

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  ImgixAspectRatio,
  ImgixImage
} from "@sixty-six-north/ui-system"
import { Elements } from "prismic-reactjs"
import React from "react"
import { PrismicLinkHook } from "./PrismicLinks"

// -- Function to add unique key to props
const propsWithUniqueKey = (props, key) => {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer
export const htmlSerializer =
  (linkResolver: PrismicLinkHook, markAsH1?: boolean) =>
  (type, element, content, children, key) => {
    let props = {}
    switch (type) {
      case Elements.heading1: // Heading 1
        return (
          <H1
            as={markAsH1 ? "h1" : "h2"}
            key={key}
            className="color-mode-title styled-h1"
          >
            {children}
          </H1>
        )

      case Elements.heading2: // Heading 2
        return (
          <H2
            as={markAsH1 ? "h1" : "h2"}
            key={key}
            className="color-mode-title styled-h2"
          >
            {children}
          </H2>
        )

      case Elements.heading3: // Heading 3
        return (
          <H3
            as={markAsH1 ? "h1" : "h3"}
            key={key}
            className="color-mode-title styled-h3"
          >
            {children}
          </H3>
        )

      case Elements.heading4: // Heading 4
        return (
          <H4
            as={markAsH1 ? "h1" : "h4"}
            key={key}
            className="color-mode-title styled-h4"
          >
            {children}
          </H4>
        )

      case Elements.heading5: // Heading 5
        return (
          <H5
            as={markAsH1 ? "h1" : "h5"}
            key={key}
            className="color-mode-title styled-h5"
          >
            {children}
          </H5>
        )

      case Elements.heading6: // Heading 6
        return (
          <H6
            as={markAsH1 ? "h1" : "h6"}
            key={key}
            className="color-mode-title styled-h6"
          >
            {children}
          </H6>
        )

      case Elements.paragraph: // Paragraph
        return React.createElement(
          "p",
          propsWithUniqueKey(
            { className: "color-mode-paragraph", ...props },
            key
          ),
          children
        )

      case Elements.preformatted: // Preformatted
        return React.createElement(
          "pre",
          propsWithUniqueKey({ className: "color-mode-pre", ...props }, key),
          children
        )

      case Elements.strong: // Strong
        return React.createElement(
          "strong",
          propsWithUniqueKey(
            { className: "color-mode-paragraph", ...props },
            key
          ),
          children
        )

      case Elements.em: // Emphasis
        return React.createElement(
          "em",
          propsWithUniqueKey(
            { className: "color-mode-paragraph", ...props },
            key
          ),
          children
        )

      case Elements.listItem: // Unordered List Item
        return React.createElement(
          "li",
          propsWithUniqueKey({ className: "color-mode-list", ...props }, key),
          children
        )

      case Elements.oListItem: // Ordered List Item
        return React.createElement(
          "li",
          propsWithUniqueKey({ className: "color-mode-list", ...props }, key),
          children
        )

      case Elements.list: // Unordered List
        return React.createElement(
          "ul",
          propsWithUniqueKey({ className: "color-mode-list", ...props }, key),
          children
        )

      case Elements.oList: // Ordered List
        return React.createElement(
          "ol",
          propsWithUniqueKey({ className: "color-mode-list", ...props }, key),
          children
        )

      case Elements.image: // Image
        const linkUrl = element.linkTo
          ? element.linkTo.url ||
            linkResolver.linkForDocumentId(element.linkTo)?.as
          : null
        const linkTarget =
          element.linkTo && element.linkTo.target
            ? { target: element.linkTo.target }
            : {}
        const linkRel = linkTarget.target ? { rel: "noopener" } : {}
        // const img = React.createElement("img", { src: element.url , alt: element.alt || "" })
        const img = (
          <ImgixImage
            aspectRatio={ImgixAspectRatio.ar_none}
            imageUrl={element.url}
            imageAlt={element.alt}
            width={element.dimensions.width}
            height={element.dimensions.height}
            sizes={"40vw"}
          />
        )
        return React.createElement(
          "p",
          propsWithUniqueKey(
            { className: [element.label || "", "block-img"].join(" ") },
            key
          ),
          linkUrl
            ? React.createElement(
                "a",
                { href: linkUrl, ...linkTarget, ...linkRel },
                img
              )
            : img
        )

      case Elements.embed: // Embed
        props = Object.assign(
          {
            "data-oembed": element.oembed.embed_url,
            "data-oembed-type": element.oembed.type,
            "data-oembed-provider": element.oembed.provider_name
          },
          element.label ? { className: element.label } : {}
        )
        const embedHtml = React.createElement("div", {
          dangerouslySetInnerHTML: { __html: element.oembed.html }
        })
        return React.createElement(
          "div",
          propsWithUniqueKey({ className: "color-mode-title", ...props }, key),
          embedHtml
        )

      case Elements.hyperlink: // Image
        const targetAttr = element.data.target
          ? { target: element.data.target }
          : {}
        const relAttr = element.data.target ? { rel: "noopener" } : {}
        props = Object.assign(
          {
            href:
              element.data.url ||
              linkResolver.linkForDocumentId(element.data.id)?.as
          },
          targetAttr,
          relAttr
        )
        return React.createElement(
          "a",
          propsWithUniqueKey({ className: "color-mode-title", ...props }, key),
          children
        )

      case Elements.label: // Label
        props = element.data
          ? Object.assign({}, { className: element.data.label })
          : {}
        return React.createElement(
          "span",
          propsWithUniqueKey({ className: "color-mode-title", ...props }, key),
          children
        )

      case Elements.span: // Span
        if (content) {
          return content.split("\n").reduce((acc, p) => {
            if (acc.length === 0) {
              return [p]
            } else {
              const brIndex = (acc.length + 1) / 2 - 1
              const br = React.createElement(
                "br",
                propsWithUniqueKey({}, brIndex)
              )
              return acc.concat([br, p])
            }
          }, [])
        } else {
          return null
        }

      default:
        // Always include a default that returns null
        return null
    }
  }
