import { ImageVariants, Ratios } from "@sixty-six-north/ui-system"

export const LayoutRatio = (r: Ratios) => {
  switch (r) {
    case "16:9":
      return ImageVariants.ratio_16x9
    case "1:1":
      return ImageVariants.ratio_1x1
    case "3:4":
      return ImageVariants.ratio_3x4
    case "4:3":
      return ImageVariants.ratio_4x3
    case "21:9":
      return ImageVariants.ratio_21x9
    case "none":
      return ImageVariants.ratio_none
    default:
      return ImageVariants.ratio_16x9
  }
}
