import { Option } from "funfix-core"

export interface NewsletterSignUpTrackingData {
  campaign: string
  source: string
  medium?: string
  content?: string
}
export const extractUTMData = (
  possibleSearchParams?: string
): Option<NewsletterSignUpTrackingData> => {
  return Option.of(possibleSearchParams).flatMap(searchParams => {
    const query = new URLSearchParams(searchParams)
    const signUpCampaign = query.get("utm_campaign") || query.get("campaign")
    const signUpSource = query.get("utm_source") || query.get("source")
    const signUpMedium = query.get("utm_medium") || query.get("medium")
    const signUpContent = query.get("utm_content")

    return Option.of(signUpCampaign)
      .flatMap(campaign =>
        Option.of(signUpSource).map(source => {
          return {
            campaign,
            source
          }
        })
      )
      .flatMap(campaignAndSource => {
        return Option.of(signUpContent)
          .map(content => {
            return {
              ...campaignAndSource,
              content
            }
          })
          .orElse(Option.of(campaignAndSource))
          .map(signUpDataSoFar => {
            return Option.of(signUpMedium)
              .map(medium => {
                return {
                  ...signUpDataSoFar,
                  medium
                }
              })
              .getOrElse(signUpDataSoFar)
          })
      })
  })
}
