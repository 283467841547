//jsxhook

import { useCustomerState } from "customer/CustomerHooks"
import { useStoreContext } from "i18n/StoreHooks"
import React from "react"
import { SingleProductProps } from "./Product"

interface TruefitProps {
  product: SingleProductProps
}

export const TrueFitWidget: React.FC<TruefitProps> = ({ product }) => {
  const customer = useCustomerState()
  const {
    // @ts-ignore
    region: { country, language }
  } = useStoreContext()
  const locale = `${language}_${country.toUpperCase()}`
  return (
    <div
      sx={{
        width: "100%",
        mb: 16,
        minHeight: 16
      }}
      className="tfc-fitrec-product"
      data-styleid={product.key || ""}
      data-colorid={product.color.selected.colorName || ""}
      data-userid={customer?.id || ""}
      data-locale={locale}
    />
  )
}
