//jsxhook

import { Box } from "theme-ui"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import React, { useEffect } from "react"
import { Router } from "next/router"

const ISSUU_EMBED_JS = "//e.issuu.com/embed.js"

export const EmbedSilce: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)

  let styles = {}
  let wrapperStyles = {}

  if (data.primary.embed?.provider_name === "YouTube") {
    styles = {
      pb: `${(adapter.embedHeight(0) / adapter.embedWidth(0)) * 100}%`
    }
    wrapperStyles = {
      pr: data.primary.width === "half_left" ? [0, 0, 0, 24] : 0,
      pl: data.primary.width === "half_right" ? [0, 0, 0, 24] : 0
    }
  } else if (data.primary.embed?.provider_name === "Spotify") {
    styles = {
      pb: adapter.embedHeight(0)
    }
  } else {
    styles = {
      pb: adapter.embedHeight(0)
    }
  }

  useEffect(() => {
    if (data.primary.embed?.provider_name === "Issuu") {
      const script = document.createElement("script")
      script.src = ISSUU_EMBED_JS
      script.async = true
      document.body.appendChild(script)
    }
  }, [])

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          position: "relative",
          ...wrapperStyles
        }}
      >
        <Box
          dangerouslySetInnerHTML={{ __html: adapter.embedHTML("") }}
          sx={{
            width: "100%",
            height: "auto",
            position: "relative",
            ...styles,
            iframe: {
              width: "100%",
              height: "100%",
              position: "absolute"
            }
          }}
        />
      </Box>
    </PrismicElementWrapper>
  )
}
